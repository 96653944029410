import { Button, CircularProgress } from "@mui/material";
import React, { useCallback, useRef, useState, useEffect } from "react";
import { toast } from "react-toastify";
import Webcam from "react-webcam";
import { WebCamFrame } from "../../../../assets/images";
import { dispatch } from "../../../../redux";
import b64toBlob from "../../../../utils/b64toBlob";
import { API } from "../../../../services/api";
import { Wrapper } from "./style";
import { useNavigate } from "react-router-dom";
import routeNames from "../../../../constants/routeNames";
import getUserRole from "../../../../utils/getUserRole";
import { useTranslation } from "react-i18next";

const videoConstraints = {
  width: 500,
  height: 500,
  facingMode: "user",
};

const FaceID = ({ onCloseClick, id }) => {
  const navigate = useNavigate();
  const webcamRef = useRef(null);
  const token = localStorage.getItem("token")?.slice(7);
  const userRole = getUserRole(token);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    navigator.permissions
      .query({ name: "camera" })
      .then((permissionObj) => {
        navigator.mediaDevices
          .getUserMedia({ video: true })
          .then((res) => console.log(res))
          .catch((handleDeny) => console.log(handleDeny));
        console.log(permissionObj.state);
      })
      .catch((error) => {
        console.log("Got error :", error);
        alert("Got error :" + error);
      });
    navigator.getUserMedia(
      {
        video: {
          facingMode: "user",
        },
      },
      function (stream) {
        console.log(stream);
      },
      (err) => console.log(err)
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const redirect =
    userRole === "ROLE_ADMIN" ? routeNames.ADMIN : routeNames.USER_COMPANIES;

  const capture = useCallback(async () => {
    const imageSrc = webcamRef.current.getScreenshot();
    const base64Data = imageSrc.replace(/^data:image\/jpeg;base64,/, "");
    // Convert the base64 string to a Blob
    const blob = b64toBlob(base64Data, "image/jpeg");
    // Create a FormData object
    const formData = new FormData();
    // Append the blob to the form data
    formData.append("file", blob, "image.jpg");
    setIsLoading(true);
    await API.faceIdCheck({ file: formData, id: id })
      .then((res) => {
        if (res.data?.Reponse) {
          toast.success(t("faceId.accessSuccessful"));
          dispatch.auth.auth();
          navigate(redirect);
        } else {
          toast.error(t("faceId.accessError"));
          onCloseClick();
        }
      })
      .catch((err) => {
        toast.error(t("faceId.accessError"));
        dispatch.auth.logoutAsync();
        onCloseClick();
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [webcamRef, id]);

  return (
    <Wrapper>
      <div className="container-face">
        <Button
          onClick={() => {
            dispatch.auth.logoutAsync();
            onCloseClick();
          }}
          className="close-btn"
        >
          X
        </Button>
        <div className="face-inner">
          <h1 className="title">{t("faceId.lookAtCamera")}</h1>
          <div className="webcam-container">
            <Webcam
              audio={false}
              width={500}
              height={500}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              videoConstraints={videoConstraints}
            />
            <div className="frame-container">
              <div className="h-blur" />
              <div style={{ height: "100%" }}>
                <div className="v-blur" />
                <img src={WebCamFrame} alt="" className="img" />
                <div className="v-blur" />
              </div>
              <div className="h-blur" />
            </div>
          </div>
          <Button
            variant="contained"
            onClick={capture}
            className="capute-button"
          >
            {isLoading ? (
              <CircularProgress
                color="success"
                style={{ width: "40px", height: "40px" }}
              />
            ) : (
              t("faceId.takePhoto")
            )}
          </Button>
        </div>
      </div>
    </Wrapper>
  );
};

export default FaceID;
