import { Link } from "react-router-dom";
import { Wrapper } from "./style";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { useMutation } from "react-query";
import { API } from "../../../../../services/api";
import { toast } from "react-toastify";
import routeNames from "../../../../../constants/routeNames";
import { editInputText, statusLogin, statusText } from "../../../../../utils";
import { useTranslation } from "react-i18next";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { BASE_URL, USERS_ROLES } from "../../../../../constants";
import image from "../../../../../assets/images/default.png";
import { MyButton } from "../../../../../components";
import { useSelector } from "react-redux";

function CreateUser() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { lng } = useSelector((store) => store.language);

  const [showPassword, setShowPassword] = useState(false);
  const [faceId, setFaceId] = useState(false);
  const [userImage, setUserImg] = useState("");
  const [moderData, setModerData] = useState({
    fullName_uz: "",
    fullName_en: "",
    fullName_ru: "",
    lastName_uz: "",
    lastName_en: "",
    lastName_ru: "",
    activated: true,
    pinfl: "",
    login: "",
    password: "",
    authTypeEnum: 3,
    phoneNumber: "",
    position_uz: "",
    position_en: "",
    position_ru: "",
    authorities: [],
    imageUrl: userImage ? `${BASE_URL}/agenda-file/${userImage[0]?.id}` : null,
  });

  const { isLoading: isUpdating, mutate } = useMutation(async (payload) => {
    return await API.createUserData(payload)
      .then((res) => {
        toast.success(t("dataUpdated"));
        navigate(routeNames.ADMIN_USER);
      })
      .catch((err) => {
        toast.error(t("errorOccurred"));
      });
  });

  const handleChange = (key, value) => {

    setModerData((prevState) => ({ ...prevState, [key]: value }));
  };

  const onPasswordToggleClick = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const submitData = { ...moderData };
    mutate(submitData);
  };

  const { mutate: fileMutate, isLoading: fileLoading } = useMutation(
    async (payload) => {
      return await API.createAgendaFile(payload)
        .then((res) => {
          setUserImg(res.data);

          setModerData({
            ...moderData,
            imageUrl: `${BASE_URL}/agenda-file/${res.data[0]?.id}`,
          });

          toast.success(t("imageUploaded"));
        })
        .catch((err) => {
          toast.error(t("imageNotUploaded"));
        });
    }
  );

  const { mutate: deleteUserImage, isLoading: deleteUserImageLoading } =
    useMutation(async (id) => {
      return await API.userProfileImageDelete(id)
        .then((res) => {
          toast.success(t("imageDeleted"));
          setModerData({
            ...moderData,
            imageUrl: "",
          });
          return res.data;
        })
        .catch((err) => {
          toast.error(t("imageNotDeleted"));
          console.log(err);
        });
    });

  const handleClick = (file) => {
    const newFormData = new FormData();
    newFormData.append("files", file);
    fileMutate(newFormData);
  };

  const deleteUserImg = () => {
    deleteUserImage(moderData?.id);
  };

  return (
    <Wrapper>
      <div className="container">
        <Box className="create-top">
          <Link to="/admin">{t("home")}</Link>
          <Link to="/admin/user">{t("userManagement")}</Link>
          <h5>{t("UsersHeading.save")}</h5>
        </Box>
        <form onSubmit={onSubmit} className="create-form">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "20px",
              marginBottom: "30px",
            }}
          >
            <Box className="img-box">
              {fileLoading ? (
                <span className="img-loader">
                  {" "}
                  <CircularProgress
                    color="success"
                    style={{ width: "80px", height: "80px" }}
                  />
                </span>
              ) : !!moderData?.imageUrl ? (
                <img src={moderData?.imageUrl} alt="LikeIcon" />
              ) : (
                <img src={image} alt="LikeIcon" />
              )}
            </Box>

            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <label htmlFor="image-upload" className="profile-image-upload">
                {t("imageUpload")}
                <input
                  id="image-upload"
                  name="image-upload"
                  type="file"

                  style={{ display: "none" }}
                  onChange={(e) => handleClick(e.target.files[0])}
                />
              </label>
              <MyButton
                border="#ff0000"
                color="red"
                background="#fff"
                weight="500"
                backgroundHover="red"
                hoverColor="#fff"
                onClick={deleteUserImg}
              >
                {deleteUserImageLoading ? (
                  <CircularProgress
                    color="success"
                    style={{ width: "24px", height: "24px" }}
                  />
                ) : (
                  t("delete")
                )}
              </MyButton>
            </Box>
            <br/>
            <div style={{fontStyle:"italic"}}>"Rasm hajmi 600x600 hajmda bo'lishi kerak"</div>
          </Box>
          <Box className="form-list">
            <label>
              <span>{t("AdminUser.fullName")}_uz *</span>
              <input
                  value={moderData?.lastName_uz}
                  onChange={(e) =>
                      handleChange("lastName_uz", editInputText(e.target.value))
                  }
                  type="text"
                  required
                  placeholder={t("companyForm.enter")}
              />
            </label>

            <label>
              {t("AdminUser.fullName")}_en *
              <input
                  value={moderData?.lastName_en}
                  onChange={(e) =>
                      handleChange("lastName_en", editInputText(e.target.value))
                  }
                  type="text"
                  required
                  placeholder={t("companyForm.enter")}
              />
            </label>

            <label>
              {t("AdminUser.fullName")}_ru *
              <input
                  value={moderData?.lastName_ru}
                  onChange={(e) =>
                      handleChange("lastName_ru", editInputText(e.target.value))
                  }
                  type="text"
                  required
                  placeholder={t("companyForm.enter")}
              />
            </label>


            <label>
              {t("protocolFullName")}_uz *
              <input
                  type="text"
                  value={moderData?.fullName_uz}
                  onChange={(e) =>
                      handleChange("fullName_uz", editInputText(e.target.value))
                  }
                  required
                  placeholder={t("companyForm.enter")}
              />
            </label>


            <label>
              {t("protocolFullName")}_en *
              <input
                  type="text"
                  value={moderData?.fullName_en}
                  onChange={(e) =>
                      handleChange("fullName_en", editInputText(e.target.value))
                  }
                  required
                  placeholder={t("companyForm.enter")}
              />
            </label>

            <label>
              {t("protocolFullName")}_ru *
              <input
                  type="text"
                  value={moderData?.fullName_ru}
                  onChange={(e) =>
                      handleChange("fullName_ru", editInputText(e.target.value))
                  }
                  required
                  placeholder={t("companyForm.enter")}
              />
            </label>

            <label>
              {t("meetingCreated.reestr.position")}_uz *
              <input
                  value={moderData?.position}
                  onChange={(e) =>
                      handleChange("position_uz", editInputText(e.target.value))
                  }
                  type="text"
                  required
                  placeholder={t("companyForm.enter")}
              />
            </label>

            <label>
              {t("meetingCreated.reestr.position")}_en *
              <input
                  value={moderData?.position}
                  onChange={(e) =>
                      handleChange("position_en", editInputText(e.target.value))
                  }
                  type="text"
                  required
                  placeholder={t("companyForm.enter")}
              />
            </label>

            <label>
              {t("meetingCreated.reestr.position")}_ru *
              <input
                  value={moderData?.position}
                  onChange={(e) =>
                      handleChange("position_ru", editInputText(e.target.value))
                  }
                  type="text"
                  required
                  placeholder={t("companyForm.enter")}
              />
            </label>

            <label>
              {t("user.userType")} *
              <Select
                  value={moderData?.role}
                  onChange={(e) => handleChange("authorities", [e.target.value])}
                  className="create-select"
              >
                <MenuItem value={USERS_ROLES.ADMIN}>{t("user.admin")}</MenuItem>
                <MenuItem value={USERS_ROLES.USER}>{t("user.user")}</MenuItem>
              </Select>
            </label>
            <label>
              {t("pinfl")} ({t("numbers")})
              <input
                  value={moderData?.pinfl}
                  style={{
                    border: `1px solid ${
                        moderData.pinfl.length < 14 && moderData.pinfl.length > 0
                            ? "red"
                            : "green"
                    }`,
                  }}
                  onChange={(e) => {
                    handleChange("pinfl", e.target.value.slice(0, 14));
                    handleChange("login", `uz-${e.target.value.slice(0, 14)}`);
                  }}
                  type="number"
                  placeholder={t("companyForm.enter")}
              />
            </label> <label>
            {t("companiesList.phoneNumber")}
            <input
                value={moderData?.phoneNumber}
                onChange={(e) => handleChange("phoneNumber", e.target.value)}
                type="number"
                placeholder={t("companyForm.enter")}
            />
          </label>

            {/*<label>*/}
            {/*  {t("enterMethod")}*/}
            {/*  <Select*/}
            {/*      className="create-select"*/}
            {/*      value={moderData?.authTypeEnum}*/}
            {/*      onChange={(e) => handleChange("authTypeEnum", e.target.value)}*/}
            {/*      label={t("enterMethod")}*/}
            {/*      required*/}
            {/*      id="outlined-password-inputs"*/}
            {/*  >*/}
            {/*    {statusLogin(t)?.map((loginStatus) => (*/}
            {/*        <MenuItem key={loginStatus?.id} value={loginStatus?.value}>*/}
            {/*          {loginStatus?.text}*/}
            {/*        </MenuItem>*/}
            {/*    ))}*/}
            {/*  </Select>*/}
            {/*</label>*/}

            <label>
              {t("user.login")} *
              <input
                  value={moderData?.login}
                  onChange={(e) => handleChange("login", e.target.value)}
                  type="login"
                  required
                  placeholder={t("companyForm.enter")}
              />
            </label>

            <label>
              {t("password")} *
              <div className="form-item">
                <input
                    onChange={(e) => handleChange("password", e.target.value)}
                    type={showPassword ? "text" : "password"}
                    required
                    placeholder={t("companyForm.enter")}
                />
                <div className="eye-icon" onClick={onPasswordToggleClick}>
                  {showPassword ? (
                      <VisibilityIcon className="icon"/>
                  ) : (
                      <VisibilityOffIcon className="icon"/>
                  )}
                </div>
              </div>
            </label>

            <label>
              {t("prePassword")} *
              <div className="form-item">
                <input
                    onChange={(e) => handleChange("password", e.target.value)}
                    type={showPassword ? "text" : "password"}
                    required
                    placeholder={t("companyForm.enter")}
                />
                <div className="eye-icon" onClick={onPasswordToggleClick}>
                  {showPassword ? (
                      <VisibilityIcon className="icon"/>
                  ) : (
                      <VisibilityOffIcon className="icon"/>
                  )}
                </div>
              </div>
            </label>
          </Box>

          <Box className="create-bottom">
            <FormControlLabel
                control={
                  <Checkbox value={faceId} onChange={() => setFaceId(!faceId)}/>
                }
                label="FaceID On/Off"
            />
          </Box>
          <Box className="create-submit">
            <Button
                className="form-submit"
                onClick={onSubmit}
                variant="contained"
                color="success"
                type="submit"
            >
              {isUpdating ? <CircularProgress/> : t("buttonCreate")}
            </Button>
          </Box>
        </form>
      </div>
    </Wrapper>
  );
}

export default CreateUser;
