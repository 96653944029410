import React from "react";
import { StyledCompanyEditModal, Wrapper } from "./style";
import { toast } from "react-toastify";
import { useMutation } from "react-query";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  TextField,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { dispatch } from "../../../../../../redux";
import { API } from "../../../../../../services/api";
import routeNames from "../../../../../../constants/routeNames";
import { editInputText } from "../../../../../../utils";

const getCompanyData = async ({ companies, setLoading, setValue }) => {
  const response = await API.getCompanyDetailsBySecretaryId(companies)
    .then((res) => {
      if (res.data) {
        const transformDescription = (text) =>
          editInputText(
            text.replaceAll("&nbsp;", " ").replaceAll("<br>", "\n")
          );
        setValue("id", res.data.id);
        setValue("name_uz", res.data.name_uz);
        setValue("name_en", res.data.name_en);
        setValue("name_ru", res.data.name_ru);
        setValue("secretaryId", res.data.secretaryId);
        setValue(
          "description_uz",
          transformDescription(res.data.description_uz)
        );
        setValue(
          "description_en",
          transformDescription(res.data.description_en)
        );
        setValue(
          "description_ru",
          transformDescription(res.data.description_ru)
        );
        setValue("secondDescription_uz", res.data.secondDescription_uz);
        setValue("secondDescription_en", res.data.secondDescription_en);
        setValue("secondDescription_ru", res.data.secondDescription_ru);
        setValue("title_uz", res.data.title_uz);
        setValue("title_en", res.data.title_en);
        setValue("title_ru", res.data.title_ru);
        setValue("secretaryId", res.data.secretaryId);
      }
    })
    .catch((error) => console.log("Fetch Single Companies Error", error))
    .finally(() => setLoading(false));
  return response;
};

function CompanyEditModal({ setIsEditCompany }) {
  const { register, handleSubmit, watch, setValue } = useForm();
  const location = useLocation();
  const companies = location?.state?.companyId;
  const [variants, setVariants] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { lng } = useSelector((store) => store.language);
  const userId = useSelector((state) => console.log(state));

  useEffect(() => {
    getCompanyData({
      companies,
      setLoading,
      companies,
      setValue,
    });
  }, []);

  const { isLoading: isUpdating, mutate } = useMutation(async (payload) => {
    return await API.updateCompanyDetails(payload)
      .then((res) => {
        toast.success(t("adminToast.infoEditSuccessful"));
        setIsEditCompany(false);
      })
      .catch((err) => {
        console.log("Mutation err", err);
        toast.error(t("adminToast.infoError"));
      });
  });

  const onSubmit = (data) => {
    const postData = {
      ...data,
    };
    mutate(postData, {
      onError: () => {
        toast.error(t("adminToast.companyPostInfoError"), {
          position: "top-right",
        });
      },
    });
  };

  if (loading) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height={"80vh"}
      >
        <CircularProgress
          color="success"
          style={{ width: "100px", height: "100px" }}
        />
      </Box>
    );
  }

  const ErrorText = ({ text }) => (
    <p
      style={{
        position: "absolute",
        bottom: "-30px",
        color: "red",
      }}
    >
      {text}
    </p>
  );

  return (
    <StyledCompanyEditModal>
      <Wrapper>
        <div className="container">
          <form className="create-form" onSubmit={handleSubmit(onSubmit)}>
            <Box className="form_inner">
              <Box className="form_inner_start">
                <label>
                  <span>{t("companyForm.name")}_uz *</span>

                  <input
                    {...register("name_uz", { required: true })}
                    type="text"
                    required
                    placeholder={t("companyForm.enter")}
                    className="form-input"
                  />
                </label>

                <label>
                  <span>{t("companyForm.name")}_en *</span>

                  <input
                    {...register("name_en", { required: true })}
                    type="text"
                    required
                    placeholder={t("companyForm.enter")}
                    className="form-input"
                  />
                </label>

                <label>
                  <span>{t("companyForm.name")}_ru *</span>

                  <input
                    {...register("name_ru", { required: true })}
                    type="text"
                    required
                    placeholder={t("companyForm.enter")}
                    className="form-input"
                  />
                </label>

                <label>
                  <span>{t("pages.company.companyTitle") + " uz"}</span>
                  <input
                    type="text"
                    placeholder={t("companyForm.enter")}
                    {...register("title_uz")}
                    className="form-input"
                  ></input>
                </label>

                <label>
                  <span>{t("pages.company.companyTitle") + " en"}</span>
                  <input
                    type="text"
                    placeholder={t("companyForm.enter")}
                    {...register("title_en")}
                    className="form-input"
                  ></input>
                </label>

                <label>
                  <span>{t("pages.company.companyTitle") + " ru"}</span>
                  <input
                    type="text"
                    placeholder={t("companyForm.enter")}
                    {...register("title_ru")}
                    className="form-input"
                  ></input>
                </label>
              </Box>

              <Box className="form_inner_end">
                <label>
                  <span>{t("companiesList.description1")}_uz</span>
                  <textarea
                    // style={{ width: 460 }}
                    placeholder={t("companyForm.enter")}
                    {...register("description_uz")}
                    className="form-textarea"
                  ></textarea>
                </label>

                <label>
                  <span>{t("companiesList.description1")}_en</span>
                  <textarea
                    // style={{ width: 460 }}
                    placeholder={t("companyForm.enter")}
                    {...register("description_en")}
                    className="form-textarea"
                  ></textarea>
                </label>

                <label>
                  <span>{t("companiesList.description1")}_ru</span>
                  <textarea
                    // style={{ width: 460 }}
                    placeholder={t("companyForm.enter")}
                    {...register("description_ru")}
                    className="form-textarea"
                  ></textarea>
                </label>

                <label style={{ marginTop: "15px" }}>
                  <span>{t("meetingCreated.meetingExtraInfo")}_uz</span>
                  <input
                    type="text"
                    placeholder={t("companyForm.enter")}
                    {...register("secondDescription_uz")}
                    className="form-input"
                  ></input>
                </label>

                <label style={{ marginTop: "15px" }}>
                  <span>{t("meetingCreated.meetingExtraInfo")}_en</span>
                  <input
                    type="text"
                    placeholder={t("companyForm.enter")}
                    {...register("secondDescription_en")}
                    className="form-input"
                  ></input>
                </label>

                <label style={{ marginTop: "15px" }}>
                  <span>{t("meetingCreated.meetingExtraInfo")}_ru</span>
                  <input
                    type="text"
                    placeholder={t("companyForm.enter")}
                    {...register("secondDescription_ru")}
                    className="form-input"
                  ></input>
                </label>
              </Box>
            </Box>
          </form>
        </div>
        <Box className="create-submit">
          <Button
            sx={{ mx: 1 }}
            className="form-submit"
            variant="outlined"
            onClick={() => setIsEditCompany(false)}
          >
            {t("cancel")}
          </Button>
          <Button
            className="form-submit btn_submit"
            variant="contained"
            color="success"
            type="submit"
            onClick={handleSubmit(onSubmit)}
          >
            {isUpdating ? (
              <CircularProgress />
            ) : (
              t("userPage.protocol.editCompany")
            )}
          </Button>
        </Box>
      </Wrapper>
    </StyledCompanyEditModal>
  );
}

export default CompanyEditModal;
