import { Box, CircularProgress } from "@mui/material";
import React, { useState } from "react";
import { SearchIcon } from "../../../../../assets/icons";
import { useMutation, useQuery } from "react-query";
import { API } from "../../../../../services/api";
import { MyButton } from "../../../../../components";
import {
  SearchMeetingBox,
  SearchMeetingListBox,
  SearchMeetingListInner,
} from "./meetingSearchStyle";
import { useForm } from "react-hook-form";
import { meetingGetCities } from "../MeetingCreate/General/queryColbackFun";
import SearchMeetingList from "./SearchMeetingList";
import { useTranslation } from "react-i18next";

const SearchMeeting = ({ mutate, meetingDeleteLoading }) => {
  const [tooltipActive, setTooltipActive] = useState(false);
  const { t } = useTranslation();
  const { register, handleSubmit, getValues, setValue, watch } = useForm();

  const { data: cities } = useQuery("cities", meetingGetCities);
  const {
    mutate: searchMeeting,
    data,
    isLoading,
  } = useMutation(async (payload) => {
    return await API.getSearchMeeting(payload)
      .then((res) => res.data)
      .catch((err) => console.log(err));
  });

  document.body.onclick = (evt) => {
    if (!!evt.target.parentElement) {
      const element = (value) => evt.target.parentElement.matches("." + value);
      const isTrue =
        element("search-list") ||
        element("search-label") ||
        element("search-box") ||
        element("search-list") ||
        element("meeting-search-delete") ||
        element("menu-box") ||
        element("search-list-footer") ||
        element("search-list-form") ||
        element("form-date-label") ||
        element("form-select") ||
        element("form-number-label");

      if (isTrue) {
        setTooltipActive(true);
      } else {
        setTooltipActive(false);
        setValue("date", "");
        setValue("city", "");
      }
    }
  };

  const onSubmit = (data) => {
    let newData = {};

    if (data.date) {
      newData = {
        ...newData,
        cityId: getValues("city"),
      };
    } else {
      newData = {
        ...newData,
        cityId: getValues("city"),
      };
    }

    searchMeeting(newData);
  };

  return (
    <SearchMeetingBox className="search-box">
      <label onClick={() => setTooltipActive(true)} className="search-label">
        <img src={SearchIcon} alt="SearchIcon" />
        <input
          {...register("title")}
          type="text"
          name="title"
          placeholder={t("search")}
        />
      </label>

      {tooltipActive && (
        <SearchMeetingListBox className="search-list">
          <SearchMeetingListInner top={data?.length > 0}>
            {isLoading ? (
              <Box
                style={{ display: "flex", justifyContent: "center" }}
                className="loading-box"
              >
                <CircularProgress
                  color="success"
                  style={{ width: "40px", height: "40px" }}
                />
              </Box>
            ) : data?.length > 0 ? (
              <SearchMeetingList
                data={data}
                mutate={mutate}
                meetingDeleteLoading={meetingDeleteLoading}
              />
            ) : (
              !!data &&
              watch("date") && <p className="data-error">{t("noMeeting")}</p>
            )}
            <div className="search-list-footer">
              <form
                action=""
                className="search-list-form"
                onSubmit={handleSubmit(onSubmit)}
              >
                <label htmlFor="" className="form-date-label">
                  <input
                    {...register("date")}
                    name="date"
                    type="datetime-local"
                    className="form-date"
                    placeholder="MM/DD/YYYY, HH:MM"
                  />
                </label>
                <select
                  name="city"
                  id=""
                  className="form-select"
                  placeholder={t("chooseCity")}
                  {...register("city")}
                >
                  <option disabled className="select-placeholder">
                    {t("chooseCity")}
                  </option>
                  {cities?.map((el) => (
                    <option value={el?.id}>{el.nameUz}</option>
                  ))}
                </select>
                <label htmlFor="" className="form-number-label">
                  <input
                    type="number"
                    className="form-protocol-number"
                    placeholder="ID protokol"
                    name="protocolNumber"
                    {...register("protocolNumber")}
                  />
                </label>

                <MyButton
                  color="#FAFAFA"
                  background="#208667"
                  padding="8px 16px"
                  type="submit"
                >
                  {t("search")}
                </MyButton>
              </form>
            </div>
          </SearchMeetingListInner>
        </SearchMeetingListBox>
      )}
    </SearchMeetingBox>
  );
};

export default SearchMeeting;
