import styled from "styled-components";

export const StyledLayout = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const LayoutWrapper = styled.div`
  background: var(--bgImage);
  background-repeat: repeat;
  /* min-height: calc(100vh - 78px - 280px); */
`;
