import { EditBody, EditContainer } from "./style";
import MyButton from "../../../../components/UI/MyButton/MyButton";
import OneProtocol from "./OneProtocol";
import { useMutation, useQuery } from "react-query";
import { API } from "../../../../services/api";
import { Box, Button, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useContext } from "react";
import { AgendaContext } from "../../pages/MeetingDetails/context/MeetingDetailContext";
import { useTranslation } from "react-i18next";
import { editInputText } from "../../../../utils";
import { set } from "react-hook-form";

const mapingFunc = (agendas) => {
  const data = [];
  agendas?.forEach((element) => {
    const obj = {
      agendaSubjectDTO: {
        agendaId: element.id,
        id: 0,
        meetingId: element.meetingId,
        subject_uz: element.subject_uz,
        subject_ru: element.subject_ru,
        subject_en: element.subject_en,
      },
      answerQuestionDTO: {
        answers: [
          {
            agendaId: element.id,
            answer_uz: "",
            answer_ru: "",
            answer_en: "",
            id: 0,
          },
        ],
        id: 0,
        meetingId: element.meetingId,
      },
      votingTextDTO: {
        agendaId: element.id,
        meetingId: element.meetingId,
        text_uz: element.votingOptions[0].votingText_uz,
        text_ru: element.votingOptions[0].votingText_ru,
        text_en: element.votingOptions[0].votingText_en,
      },
    };
    data.push(obj);
  });

  return data;
};

const getData = async (meetingId, setProtocolData, setLoading) =>
  API.getAgendaAnswers(meetingId)
    .then((res) => {
      if (!!res.data) {
        const newData = res.data[0];
        const resData = newData.answers.sort((a, b) => a.id - b.id);
        setProtocolData((prev) => {
          return prev.map((item, idx) => ({
            ...item,
            answerQuestionDTO: {
              answers: [
                {
                  agendaId: prev[idx]?.agendaId,
                  answer_uz: prev[idx]?.answer_uz,
                  answer_ru: prev[idx]?.answer_ru,
                  answer_en: prev[idx]?.answer_en,
                  id: prev[idx]?.id,
                },
              ],
              id: newData.id,
              meetingId: newData.meetingId,
            },
          }));
        });
      }
      return res.data;
    })
    .catch((err) => console.log(err))
    .finally(() => setLoading(false));

const ProtocolEdit = ({ agendas, meetingId, setIsProtocol }) => {
  const { refetch } = useContext(AgendaContext);
  const [protocolData, setProtocolData] = useState(mapingFunc(agendas));
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    getData(meetingId, setProtocolData, setLoading);
  }, []);

  const { mutate, isLoading } = useMutation(
    async (data) =>
      await API.updateProtocol(data)
        .then((res) => {
          toast.success("successful");
          setIsProtocol(false);
        })
        .catch((err) => toast.error(t("userPage.protocol.protocolEditErr")))
  );

  if (loading || isLoading) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        width="100%"
        height="80vh"
      >
        <CircularProgress
          color="success"
          style={{ width: "100px", height: "100px" }}
        />
      </Box>
    );
  }

  const handleClick = () => {
    const newProtocolData = JSON.parse(JSON.stringify(protocolData));
    newProtocolData.forEach((el) => {
      el.answerQuestionDTO.answers[0].answer_uz = editInputText(
        el.answerQuestionDTO.answers[0].answer_uz
        .replaceAll("<p>", '<p style="text-indent: 70px;padding: 0">')
        .replaceAll("&nbsp;", "")
      );
      el.answerQuestionDTO.answers[0].answer_ru = editInputText(
        el.answerQuestionDTO.answers[0].answer_ru
        .replaceAll("<p>", '<p style="text-indent: 70px;padding: 0">')
        .replaceAll("&nbsp;", "")
      );
      el.answerQuestionDTO.answers[0].answer_en = editInputText(
        el.answerQuestionDTO.answers[0].answer_en
        .replaceAll("<p>", '<p style="text-indent: 70px;padding: 0">')
        .replaceAll("&nbsp;", "")
      );
      
    });
    mutate(newProtocolData);
  };

  return (
    <EditContainer>
      <EditBody>
        {protocolData?.map((item, idx) => {
          return (
            <OneProtocol
              protocol={item}
              setProtocolData={setProtocolData}
              idx={idx}
            />
          );
        })}
      </EditBody>
      <div style={{ display: "flex", gap: "10px" }}>
        <Button
          sx={{
            mx: 1,
            borderRadius: 2,
          }}
          variant="outlined"
          onClick={() => setIsProtocol(false)}
        >
          {t("cancel")}
        </Button>
        <MyButton
          width="210px"
          background="#208667"
          color="#fff"
          onClick={handleClick}
        >
          {t("userPage.protocol.protocolEditBtn")}
        </MyButton>
      </div>
    </EditContainer>
  );
};

export default ProtocolEdit;
