import React from "react";
import {styled} from "styled-components";
import ProtocolEditHtml from "../../../Protocol/ProtocolEditHtml";

function ProtocolHtmlEditModal({ setIsProtocol, meetingId, agendas,lang,isProtocol }) {
  const StyledProtocolEditModal = styled.div`
    position: fixed;
    z-index: 999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
  `;

  return (
    <StyledProtocolEditModal>
      <ProtocolEditHtml
          setIsProtocol={setIsProtocol}
          meetingId={meetingId}
          agendas={agendas}
          lang={lang}
          isProtocol={isProtocol}
      />
    </StyledProtocolEditModal>
  );
}

export default ProtocolHtmlEditModal;
