import React from "react";
import { LayoutWrapper, StyledLayout } from "./style";
import { useSelector } from "react-redux";
import Navbar from "../Navbar";
import NewHeader from "../NewHeader";
import Footer from "../Footer";

const Layout = ({ children }) => {
  const { isLogged, user_role } = useSelector((store) => store.auth);

  const Nav = isLogged && !!user_role ? NewHeader : Navbar;
  return (
    <StyledLayout>
      <div>
        <Nav />
        <LayoutWrapper>{children}</LayoutWrapper>
      </div>
      <Footer />
    </StyledLayout>
  );
};

export default Layout;
