import styled from "styled-components";

export const AgendaFormBox = styled.div`
  /* margin-bottom: 30px; */
  padding: 20px;
  padding-bottom: 30px;
  /* border-radius: 8px; */
  background-color: #f6fcff;

  .form-col {
    margin-bottom: 19px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .form-label {
    margin-bottom: 5px;
  }

  .form-grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(100px, 319px));
    column-gap: 20px;
  }

  .form-col-content {
    display: grid;
    grid-template-columns: minmax(100px, 579px) 48px;
    column-gap: 20px;
    margin-bottom: 10px;
  }

  .minute_input {
    position: relative;
  }

  .minute {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 50px;
    font-size: 16px;
  }

  .img_cards {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-top: 5px;

    button {
      border: none;
      background: transparent;
      cursor: pointer;

      img {
        width: 30px;
        transition: transform 0.3s ease, box-shadow 0.3s ease;

        &:hover {
          box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
          transform: scale(1.1);
        }
      }
    }
  }
`;
