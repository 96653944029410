import React, { useState } from "react";
import { AgendaBox } from "./style";

import { AgendaForm, AgendaList } from "./components";
import { ButtonSpinner, MyButton } from "../../../../../../components";
import { useMutation, useQuery } from "react-query";
import {
  createMeetingAgendaFileFunc,
  createMeetingAgendaFunc,
  getAgendaDataFunc,
} from "./extra";
import { useNavigate } from "react-router-dom";
import { API } from "../../../../../../services/api";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { useSelector } from "react-redux";

const initialState = {
  subject_en: "",
  subject_uz: "",
  subject_ru: "",
  speakerId: "",
  debateEnum: "",
  speakTimeEnum: "",
  issueInfo_uz: "",
  issueInfo_ru: "",
  issueInfo_en: "",
  editor_uz: "",
  editor_ru: "",
  editor_en: "",
};

const Agenda = ({ meetingId, setActive }) => {
  const { t } = useTranslation();
  const [data, setData] = useState(initialState);
  const [issueFile, setIssueFile] = useState([]);
  const navigate = useNavigate();
  const { lng } = useSelector((store) => store.language);

  const { data: watcherMembersData, isLoading: watcherMembersDataLoading } =
    useQuery("membersByMeetingFormResterTrue", async () => {
      const response = await API.getMembersByMeeting({
        meetingId: meetingId,
        fromReestr: false,
      })
        .then((res) => res.data)
        .catch((err) => console.log("Fetch Members Error", err));

      return response;
    });

  const { data: simpleMembersData, isLoading: simpleMembersDataLoading } =
    useQuery("membersByMeetingFormResterFalse", async () => {
      const response = await API.getMembersByMeeting({
        meetingId: meetingId,
        fromReestr: true,
      })
        .then((res) => res.data)
        .catch((err) => console.log("Fetch Members Error", err));

      return response;
    });

  const speakers = useMemo(() => {
    const arr = [];
    if (!!simpleMembersData && !!watcherMembersData) {
      [...simpleMembersData, ...watcherMembersData].forEach((member) => {
        arr.push({
          ...member,
          label:
            member?.user[
              lng === "uz"
                ? "fullName"
                : `fullName${lng.charAt(0).toUpperCase() + lng.slice(1)}`
            ],
        });
      });
    }
    return arr;
  }, [simpleMembersData, watcherMembersData, lng]);
  const {
    data: agenda,
    isLoading,
    refetch: getAgendaRefetch,
  } = useQuery("getAgendaData", getAgendaDataFunc);

  const { mutate: agendaDeleteMutate, isLoading: agendaDeleteLoading } =
    useMutation(async (payload) => {
      return await API.deleteAgenda(payload)
        .then((res) => {
          toast.success(t("issueDeleted"));
          getAgendaRefetch();
        })
        .catch((err) => {
          toast.error(t("issueNotDeleted"));
        });
    });

  const {
    mutate,
    isLoading: agendaPostLoading,
    isSuccess,
  } = useMutation((payload) =>
    createMeetingAgendaFunc({ ...payload, t }, console.log("payload => " + payload.issueFile))
  );

  const { mutate: createAgendaFile, isLoading: agendaFileLoading } =
    useMutation((payload) =>
      createMeetingAgendaFileFunc({
        ...payload,
        agendaDeleteMutate,
        setIssueFile,
        t,
      })
    );

  const handleSubmit = (e) => {
    const newAgenda = {
      active: true,
      meetingId,
      speakTimeEnum: data.speakTimeEnum,
      speakerId: data.speakerId,
      subject_uz: data.subject_uz,
      subject_ru: data.subject_ru,
      subject_en: data.subject_en,
      typeEnum: "FULL",
      variants_uz: [data.issueInfo_uz],
      variants_ru: [data.issueInfo_ru],
      variants_en: [data.issueInfo_en],
      editor_uz: data.editor_uz,
      editor_ru: data.editor_ru,
      editor_en: data.editor_en,
    };

    e.preventDefault();
    const {
      subject_uz,
      subject_ru,
      subject_en,
      speakTimeEnum,
      speakerId,
      issueInfo_uz,
      issueInfo_en,
      issueInfo_ru,
    } = data;
    if (
      !subject_uz ||
      !subject_en ||
      !subject_ru ||
      !speakTimeEnum ||
      !speakerId ||
      !issueInfo_uz ||
      !issueInfo_en ||
      !issueInfo_ru
    ) {
      if (!subject_uz && !subject_ru && !subject_en) {
        toast.warning(t("enterIssueName"));
      } else if (!speakerId) {
        toast.warning(t("chooseSpeaker"));
      } else if (!speakTimeEnum) {
        toast.warning(t("regulationTime"));
      } else if (!issueInfo_uz && !issueInfo_ru && !issueInfo_en) {
        toast.warning(t("aboutIssue"));
      } else {
        toast.warning(t("enterData"));
      }
    } else if (!!issueFile?.files?.length) {
      mutate({
        data: newAgenda,
        issueFile,
        mutate: createAgendaFile,
        refetch: getAgendaRefetch,
        setData,
        meetingId,
      });
    } else {
      toast.warning(t("enterFile"));
    }
  };

  return (
    <>
      <AgendaBox>
        <div className="inner">
          <AgendaForm
            data={data}
            setData={setData}
            speakers={speakers}
            setIssueFile={setIssueFile}
            issueFile={issueFile}
            loading={watcherMembersDataLoading || simpleMembersDataLoading}
            isSuccess={isSuccess}
          />
          {agendaPostLoading || agendaFileLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "10px 0",
                marginTop: "10px",
              }}
            >
              <ButtonSpinner />
            </div>
          ) : (
            <MyButton
              color="#208667"
              background="#FFFFFF"
              border="#208667"
              width="100%"
              margin="30px 0 0 0"
              onClick={handleSubmit}
              type="submit"
              form="agendaForm"
            >
              {t("createAgenda")}
            </MyButton>
          )}
          {isLoading ? (
            <p>Loading...</p>
          ) : (
            <AgendaList
              agendaData={agenda}
              speakers={speakers}
              getAgendaRefetch={getAgendaRefetch}
              agendaDeleteMutate={agendaDeleteMutate}
              agendaDeleteLoading={agendaDeleteLoading}
            />
          )}
        </div>

        <div className="form-box-footer">
          <MyButton
            color="#208667"
            background="#FFFFFF"
            border="#208667"
            onClick={() => {
              setActive("participant");
              navigate(`/admin/meetings/create#participant`);
            }}
          >
            {t("back")}
          </MyButton>
          <MyButton
            color="#FFFFFF"
            background="#208667"
            border="#208667"
            onClick={() => navigate(`/admin/meetings/create/${meetingId}`)}
          >
            {t("next")}
          </MyButton>
        </div>
      </AgendaBox>
    </>
  );
};

export default Agenda;
