import React, { useState, useEffect } from "react";
import {
  MeetingCard,
  MeetingLists,
  MeetingTopic,
  ShowVotes,
  StyledUsersVotes,
} from "./style";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import UsersList from "./UsersList";
import { API } from "../../../../../../services/api";
import ShowVotedUsers from "./ShowVotedUsers";

const UsersVotes = ({ agendas, members, meetingId, meetingData }) => {
  const { t } = useTranslation();
  const { lng } = useSelector((store) => store.language);
  const [isVoting, setIsVoting] = useState(false);
  const [agenda, setAgenda] = useState(null);
  const [results, setResult] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      if (meetingId) {
        try {
          const res = await API.getBallotsResultByMeetingId(meetingId);
          setResult(res.data);
        } catch (error) {
          console.error("Error fetching ballot results:", error);
        }
      }
    };
    fetchData();
  }, [isVoting]);

  if (isVoting) {
    return (
      <UsersList
        agenda={agenda}
        setIsVoting={setIsVoting}
        members={members}
        meetingId={meetingId}
        meetingData={meetingData}
      />
    );
  }

  return (
    <StyledUsersVotes>
      <MeetingLists>
        {agendas?.map((meeting) => (
          <MeetingCard
            key={meeting.id}
            onClick={() => {
              setAgenda(meeting);
              setIsVoting(true);
            }}
          >
            <MeetingTopic gTopic>{meeting[`subject_${lng}`]}</MeetingTopic>
            {results.map((result) => {
              if (result.agendaId == meeting.id) {
                return (
                  <>
                    <div className="show-votes">
                      <ShowVotes color={"#3EB250"}>
                        {t("userPage.votes.votePositive")}:
                      </ShowVotes>{" "}
                      {result.agree.length} {t("votes")}
                      <ShowVotedUsers users={result.agree} />
                    </div>
                    <div className="show-votes">
                      <ShowVotes color={"#FFC700"}>
                        {t("userPage.votes.voteNeutral")}:
                      </ShowVotes>{" "}
                      {result.refrain.length} {t("votes")}
                      <ShowVotedUsers users={result.refrain} />
                    </div>
                    <div
                      className="show-votes"
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    >
                      <ShowVotes color={"rgba(255, 0, 0, 0.90)"}>
                        {t("userPage.votes.voteControversy")}:
                      </ShowVotes>{" "}
                      {result.against.length} {t("votes")}
                      <ShowVotedUsers users={result.against} />
                    </div>
                  </>
                );
              }
            })}
          </MeetingCard>
        ))}
      </MeetingLists>
    </StyledUsersVotes>
  );
};

export default UsersVotes;
