import React from "react";
import { Route, Routes } from "react-router-dom";
import PageNotFound from "./components/PageNotFound";
import PrivateRoute from "./components/PrivateRoute";
import { PrivateRoutes, PublicRoutes } from "./routes";
import { Layout } from "../components";

function AppRoutes() {
  return (
    <>
      <Layout>
        <Routes>
          {PublicRoutes?.map((route, idx) => (
            <Route
              key={route.path + idx}
              path={route.path}
              element={route.element}
            />
          ))}
          {PrivateRoutes?.map((route, idx) => {
            return (
              <Route
                key={route?.path + idx}
                path={route.path}
                element={
                  <PrivateRoute path={route.path}>
                    {route?.element}
                  </PrivateRoute>
                }
              />
            );
          })}
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Layout>
    </>
  );
}

export default AppRoutes;
