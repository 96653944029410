import React, { useEffect, useState } from "react";
import { HeaderPage, Img } from "./style";
import { NavoiyUran } from "../../assets/icons";
import UserNameLogin from "./pages/Username";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import routeNames from "../../constants/routeNames";
import EimzoLogin from "./pages/EImzo";
import { t } from "i18next";

function Login() {
  let navigate = useNavigate();
  const auth = useSelector((store) => store.auth);
  const user = useSelector((store) => store.userData);
  const [activeTab, setActiveTab] = useState("ERI");

  const onTabClick = (value) => {
    setActiveTab(value);
  };

  useEffect(() => {
    if (auth.user_role === "ROLE_ADMIN") {
      navigate(routeNames.ADMIN);
    } else if (auth.user_role === "ROLE_USER") {
      navigate(routeNames.USER_COMPANIES);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  return (
    <HeaderPage>
      <Img src={NavoiyUran} alt="E-Protocol" />
      <p>{t("loginMethod")}</p>
      <div>
        <button
          style={{
            backgroundColor: activeTab === "ERI" ? "#208667" : null,
            color: activeTab === "ERI" ? "#fff" : null,
          }}
          className={activeTab === "ERI" ? "active" : null}
          onClick={() => onTabClick("ERI")}
        >
          {t("eds.eds")}
        </button>
        <button
          style={{
            backgroundColor: activeTab === "Username" ? "#208667" : null,
            color: activeTab === "Username" ? "#fff" : null,
          }}
          className={activeTab === "Username" ? "active" : null}
          onClick={() => onTabClick("Username")}
        >
          {t("user.login")}
        </button>
      </div>
      {activeTab === "ERI" ? (
        <EimzoLogin />
      ) : (
        // ''
        <UserNameLogin />
      )}
    </HeaderPage>
  );
}

export default Login;
