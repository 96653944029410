import { toast } from "react-toastify";
import { useMutation } from "react-query";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  TextField,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { dispatch } from "../../../../../redux";
import { API } from "../../../../../services/api";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Wrapper } from "./style";
import routeNames from "../../../../../constants/routeNames";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { editInputText } from "../../../../../utils";

const getCompanyData = async ({
  setLoading,
  companies,
  setValue,
  setVariants,
  lng
}) => {
  const response = await API.getCompanyByID(companies)
    .then((res) => {
      if (res.data) {
        console.log("secretary => ", res.data.secretary[`fullName${lng.charAt(0).toUpperCase() + lng.slice(1)}`]);
        const transformDescription = (text) =>
          editInputText(
            text.replaceAll("&nbsp;", "").replaceAll("<br>", "\n")
          );
        setValue("id", res.data.id);
        setValue("name_uz", res.data.name_uz);
        setValue("name_en", res.data.name_en);
        setValue("name_ru", res.data.name_ru);
        setValue("active", res.data.active);
        setValue("secretaryId", res.data.secretaryId);
        setValue("chairmanId", res.data.chairmanId);
        setValue("imageUrl", res.data.imageUrl);
        setValue(
          "description_uz",
          transformDescription(res.data.description_uz)
        );
        setValue(
          "description_en",
          transformDescription(res.data.description_en)
        );
        setValue(
          "description_ru",
          transformDescription(res.data.description_ru)
        );
        setValue("secondDescription_uz", res.data.secondDescription_uz);
        setValue("secondDescription_en", res.data.secondDescription_en);
        setValue("secondDescription_ru", res.data.secondDescription_ru);
        setValue("title_uz", res.data.title_uz);
        setValue("title_en", res.data.title_en);
        setValue("title_ru", res.data.title_ru);
        setValue("chairman", {
          ...res.data.chairman,
          label:
            lng === "uz"
              ? res.data.chairman.fullName || ""
              : res.data.chairman[`fullName${lng.charAt(0).toUpperCase() + lng.slice(1)}`] || "",
          value: res.data.chairman.id
        });
        setValue("secretary", {
          ...res.data.secretary,
          label:
            lng === "uz"
              ? res.data.secretary.fullName || ""
              : res.data.secretary[`fullName${lng.charAt(0).toUpperCase() + lng.slice(1)}`] || "",
          value: res.data.secretary.id
        });

        setVariants(
          res.data.participants.map((el) => ({
            ...el,
            label:
              lng === "uz"
                ? el.fullName || ""
                : el[`fullName${lng.charAt(0).toUpperCase() + lng.slice(1)}`] || "",
          }))
        );
      }
    })
    .catch((error) => console.log("Fetch Single Companies Error", error))
    .finally(() => setLoading(false));
  return response;
};

function CompanyEdit() {
  const { register, handleSubmit, watch, setValue } = useForm();
  const location = useLocation();
  const companies = location?.state?.companyId;
  const [variants, setVariants] = useState([]);
  const [loading, setLoading] = useState(true);
  const users = useSelector((store) => store?.getModerUserData);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { lng } = useSelector((store) => store.language);

  const userData = useMemo(() => {
    return !!users?.length
      ? users.map((el) => ({
          label: el[`fullName_${lng}`] ? el[`fullName_${lng}`] : "",
          ...el,
        }))
      : [];
  }, [users, lng]);

  useEffect(() => {
    dispatch.getModerUserData.getAllModerUserAsync();
    getCompanyData({
      setLoading,
      companies,
      setValue,
      setVariants,
      lng
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lng]);

  const { isLoading: isUpdating, mutate } = useMutation(async (payload) => {
    return await API.updateCompanyData(payload)
      .then((res) => {
        toast.success(t("adminToast.infoEditSuccessful"));
        navigate(routeNames.ADMIN_COMPANY);
      })
      .catch((err) => {
        console.log("Mutation err", err);
        toast.error(t("adminToast.infoError"));
      });
  });

  const handleIncrementInputs = () => {
    setVariants((prev) => [...prev, { label: "" }]);
  };

  const handleDecrementInputs = (itemIdx) => {
    setVariants((prev) => prev.filter((_, idx) => idx !== itemIdx));
  };

  const handleAddToVariantsArray = (product, elIdx) => {
    const isProductSelected = variants.some((user) => user?.id === product?.id);

    if (isProductSelected) {
      if (!!product) {
        toast.warning("bu fodalanuvchi tanlangan!");
      }
    } else {
      setVariants((prev) =>
        prev.map((el, idx) => {
          if (idx === elIdx) {
            if (!product) {
              return { label: "" };
            } else {
              return {
                ...el,
                ...product,
              };
            }
          }

          return el;
        })
      );
    }
  };

  const onSubmit = (data) => {
    const newVariants = JSON.parse(JSON.stringify(variants)).map((el) => {
      delete el.label;
      return el;
    });
    const description = editInputText(
      // "&nbsp;".repeat(14) +
      data.description
      // .replaceAll("\n", "<br>" + "&nbsp;".repeat(14))
    );
    const postData = {
      ...data,
      secretaryId: data.secretary.id.toString(),
      chairmanId: data.chairman.id,
      participants: newVariants,
      description: description,
      title: data.title ? editInputText(data.title) : "",
      name: editInputText(data.name),
      secondDescription: editInputText(data.secondDescription),
    };

    mutate(postData, {
      onError: () => {
        toast.error(t("adminToast.companyPostInfoError"), {
          position: "top-right",
        });
      },
    });
  };

  if (loading) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height={"80vh"}
      >
        <CircularProgress
          color="success"
          style={{ width: "100px", height: "100px" }}
        />
      </Box>
    );
  }

  const ErrorText = ({ text }) => (
    <p
      style={{
        position: "absolute",
        bottom: "-30px",
        color: "red",
      }}
    >
      {text}
    </p>
  );

  return (
    <Wrapper>
      <div className="container">
        <Box className="create-top">
          <Link to="/admin">{t("home")}</Link>
          <Link to="/admin/company">{t("companiesList.typeCompany")}</Link>
          <h5>Edit</h5>
        </Box>
        <form className="create-form" onSubmit={handleSubmit(onSubmit)}>
          <Box className="form_inner">
            <Box className="form_inner_start">
              <label>
                <span>{t("companyForm.name")}_uz *</span>

                <input
                  {...register("name_uz", { required: true })}
                  type="text"
                  required
                  placeholder={t("companyForm.enter")}
                  className="form-input"
                />
              </label>

              <label>
                <span>{t("companyForm.name")}_en *</span>

                <input
                  {...register("name_en", { required: true })}
                  type="text"
                  required
                  placeholder={t("companyForm.enter")}
                  className="form-input"
                />
              </label>

              <label>
                <span>{t("companyForm.name")}_ru *</span>

                <input
                  {...register("name_ru", { required: true })}
                  type="text"
                  required
                  placeholder={t("companyForm.enter")}
                  className="form-input"
                />
              </label>

              <label>
              <span>{t("pages.company.companyTitle") + " uz"}</span>
                <input
                  type="text"
                  placeholder={t("companyForm.enter")}
                  {...register("title_uz")}
                  className="form-input"
                ></input>
              </label>

              <label>
              <span>{t("pages.company.companyTitle") + " en"}</span>
                <input
                  type="text"
                  placeholder={t("companyForm.enter")}
                  {...register("title_en")}
                  className="form-input"
                ></input>
              </label>

              <label>
              <span>{t("pages.company.companyTitle") + " ru"}</span>
                <input
                  type="text"
                  placeholder={t("companyForm.enter")}
                  {...register("title_ru")}
                  className="form-input"
                ></input>
              </label>

              {variants?.map((x, i) => (
                <div
                  style={{ margin: "10px 0" }}
                  className="form-selects"
                  key={i}
                >

                  {/* edit members */}
                  <div className="form-selects-in">
                    <label>
                      <span>{t("companyForm.companyParticipants")} *</span>
                      <Autocomplete
                        className="form-select"
                        disablePortal
                        id="combo-box-demo"
                        options={!!userData ? userData : []}
                        value={
                          String(lng) === "uz"
                            ? x
                              ? x.fullName
                              : null
                            : x
                            ? x[`fullName_${lng}`]
                            : null
                        }
                        onChange={(event, newValue) => {
                          handleAddToVariantsArray(newValue, i);
                        }}
                        loadingText="Foydalanuvchi qidirilmoqda..."
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required={true}
                            label=""
                            placeholder={t("companyForm.enter")}
                          />
                        )}
                      />
                    </label>
                    {(i > 0 || variants.length === 1) && (
                      <button
                        type="button"
                        onClick={handleIncrementInputs}
                        className="form-plus"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM12 7C12.5523 7 13 7.44772 13 8V11H16C16.5523 11 17 11.4477 17 12C17 12.5523 16.5523 13 16 13H13V16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16V13H8C7.44772 13 7 12.5523 7 12C7 11.4477 7.44772 11 8 11H11V8C11 7.44772 11.4477 7 12 7Z"
                            fill="#208667"
                          />
                        </svg>
                      </button>
                    )}
                    {variants?.length > 1 && (
                      <button
                        className="form-minus"
                        type="button"
                        disabled={variants?.length <= 1}
                        onClick={() => handleDecrementInputs(i)}
                      >
                        -
                      </button>
                    )}
                  </div>
                </div>
              ))}
            </Box>

            <Box className="form_inner_end">
            <label>
                <span>{t("companyForm.companySecretary")} *</span>
                <Autocomplete
                  className="form-select"
                  disablePortal
                  id="combo-box-demo"
                  options={!!userData ? userData : []}
                  value={watch("secretary")}
                  onChange={(event, newValue) => {
                    setValue("secretaryId", newValue);
                  }}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  loadingText="Foydalanuvchi qidirilmoqda..."
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label=""
                      placeholder={t("companyForm.enter")}
                      required={true}
                    />
                  )}
                />
              </label>

              <label>
                <span>{t("companyForm.companyChairman")} *</span>
                <Autocomplete
                  className="form-select"
                  disablePortal
                  id="combo-box-demo"
                  options={!!userData ? userData : []}
                  value={watch("chairman")}
                  onChange={(event, newValue) => {
                    setValue("chairman", newValue); 
                  }}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  loadingText="Foydalanuvchi qidirilmoqda..."
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label=""
                      required={true}
                      placeholder={t("companyForm.enter")}
                    />
                  )}
                />
              </label>

              <label>
                <span>{t("companiesList.description1")}_uz</span>
                <textarea
                  // style={{ width: 460 }}
                  placeholder={t("companyForm.enter")}
                  {...register("description_uz")}
                  className="form-textarea"
                ></textarea>
              </label>

              <label>
                <span>{t("companiesList.description1")}_en</span>
                <textarea
                  // style={{ width: 460 }}
                  placeholder={t("companyForm.enter")}
                  {...register("description_en")}
                  className="form-textarea"
                ></textarea>
              </label>

              <label>
                <span>{t("companiesList.description1")}_ru</span>
                <textarea
                  // style={{ width: 460 }}
                  placeholder={t("companyForm.enter")}
                  {...register("description_ru")}
                  className="form-textarea"
                ></textarea>
              </label>

              <label style={{ marginTop: "15px" }}>
                <span>{t("meetingCreated.meetingExtraInfo")}_uz</span>
                <input
                  type="text"
                  placeholder={t("companyForm.enter")}
                  {...register("secondDescription_uz")}
                  className="form-input"
                ></input>
              </label>

              <label style={{ marginTop: "15px" }}>
                <span>{t("meetingCreated.meetingExtraInfo")}_en</span>
                <input
                  type="text"
                  placeholder={t("companyForm.enter")}
                  {...register("secondDescription_en")}
                  className="form-input"
                ></input>
              </label>

              <label style={{ marginTop: "15px" }}>
                <span>{t("meetingCreated.meetingExtraInfo")}_ru</span>
                <input
                  type="text"
                  placeholder={t("companyForm.enter")}
                  {...register("secondDescription_ru")}
                  className="form-input"
                ></input>
              </label>
            </Box>
          </Box>
          <Box className="create-submit">
            <Button
              className="form-submit"
              variant="contained"
              color="success"
              type="submit"
            >
              {isUpdating ? <CircularProgress /> : "Yaratish"}
            </Button>
          </Box>
        </form>
      </div>
    </Wrapper>
  );
}

export default CompanyEdit;
