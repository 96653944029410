import React from "react";
import { useSelector } from "react-redux";

const ShowVotedUsers = ({ users }) => {
  const { lng } = useSelector((store) => store.language);
  return (
    <div className="voted_users_list">
      {users?.map((user, i) => (
        <p key={i}>
          {i + 1}
          {". "}
          {user[`fullName`]}
        </p>
      ))}
    </div>
  );
};

export default ShowVotedUsers;

const users1 = [
  { id: 1, fullName: "Ali Rahimov Ali " },
  { id: 2, fullName: "Laylo Karimova Abdullayevna" },
  { id: 3, fullName: "Shahzod Aliyev" },
  { id: 4, fullName: "Nigora Nurmatova" },
  { id: 5, fullName: "Rustam Sattorov" },
  { id: 6, fullName: "Malika Yuldasheva" },
  { id: 7, fullName: "Aziz Usmonov" },
  { id: 8, fullName: "Kamila Shukurova" },
  { id: 9, fullName: "Jasur Otabekov" },
  { id: 10, fullName: "Olim Ibrohimov" },
];
