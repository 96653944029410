import React from "react";
// import Footer from "../../components/Footer";
import Header from "../../components/Header";

function Home() {
  return (
    <>
      <Header />
      {/* <Footer /> */}
    </>
  );
}

export default Home;
