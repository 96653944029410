export const QUERIES_NAME = {
  GET_USER_SEARCH_DATA: "getUserSearchData",
  GET_COMPANIES: "getCompanies",
  GET_COMPANY_DATA: "getCompanyData",
  GET_COMPANY_MEETINGS: "getCompanyMeetings",
  GET_MEETING: "getMeeting",
  GET_MEETING_BY_ID: "getMeetingById",
  GET_MEMBER_ROLE_BY_MEETING: "getMemberRoleByMeeting",
  GET_NOTIFICATION_MEMBER_ROLE_BY_MEETING: "getNotificationMemberRoleByMeeting",
  GET_AGENDES: "getAgendes",
  GET_FILES: "getFiles",
  GET_LOGGING_BY_MEETING: "getLoggingsByMeeting",
  CREATE_QUESTION: "createQuestion",
  GET_QUESTIONS_BY_MEETING: "getQuestionsByMeeting",
  GET_QUESTIONS_BY_MEMBER: "getQuestionsByMebmer",
  GET_VOIT: "getVoit",
  GET_BALLOT_BY_VOITNGID: "getByVoitId",
  GET_BALLOT_AGENDA_MEMBER_BY_ID: "getBallotAgendaMemberById",
  GET_MEMBERS: "getMembers",
  GET_NOTIFICATION_DATA: "getNotificationData",
  MEETING_VIDEO: "meetingVideo",
};
