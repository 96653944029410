import React, { Component } from "react";
import { Wrapper } from "./style";
// import { KeyIcon, SignIn } from "../../../../assets/icons";
import { dispatch } from "../../../../redux";

import axios from "axios";
import { API } from "../../../../services/api";
import { BASE_URL } from "../../../../constants";
import { withTranslation } from "react-i18next";

class EimzoLogin extends Component {
  EIMZO_MAJOR = 3;
  EIMZO_MINOR = 37;
  errorCAPIWS =
    "Ошибка соединения с E-IMZO. Возможно у вас не установлен модуль E-IMZO или Браузер E-IMZO.";
  errorBrowserWS =
    "Браузер не поддерживает технологию WebSocket. Установите последнюю версию браузера.";
  errorUpdateApp =
    'ВНИМАНИЕ !!! Установите новую версию приложения E-IMZO или Браузера E-IMZO.<br /><a href="https://e-imzo.uz/main/downloads/" role="button">Скачать ПО E-IMZO</a>';
  errorWrongPassword = "Пароль неверный.";

  constructor(props) {
    super(props);

    this.state = {
      ticket: "test",
      id: null,
      tin: undefined,
      ticket_session_id: undefined,
      pkcs7: undefined,
      keyId: undefined,
      login: null,
      isRegisteredBefore: null,
    };
    axios
      .get(BASE_URL + "/e-imzo")
      .then((response) => {
        this.state.ticket = response.data.id;
        this.state.id = response.data.sessionId;
        this.setState(this.state);
      })
      .catch();
    setTimeout(this.AppLoad, 2000);
    setTimeout(this.uiLoadKeys, 3000);
  }

  //   componentDidMount() {
  //     this.fetchSessionId();
  //   }

  //   fetchSessionId = async () => {
  //     await axios.get('http://176.96.243.40:8080/api/e-imzo').then(res => {
  //       this.setState(prevState => ({
  //         ...prevState,
  //         id: res.data?.sessionId,
  //         ticket: res.data?.id,
  //       }));
  //     });
  //   };

  uiLoadKeys = () => {
    console.log(window.EIMZOClient);
    this.uiClearCombo();
    window.EIMZOClient.listAllUserKeys(
      (o, i) => {
        var itemId = "itm-" + o.serialNumber + "-" + i;
        return itemId;
      },
      (itemId, v) => {
        return this.uiCreateItem(itemId, v);
      },
      (items, firstId) => {
        this.uiFillCombo(items);
        this.uiLoaded();
        this.uiComboSelect(firstId);
      },
      (e, r) => {
        if (e) {
          console.log(this.errorCAPIWS + " : " + e);
        } else {
          console.log(r);
        }
      }
    );
  };

  AppLoad = () => {
    window.EIMZOClient.API_KEYS = [
      "localhost",
      "96D0C1491615C82B9A54D9989779DF825B690748224C2B04F500F370D51827CE2644D8D4A82C18184D73AB8530BB8ED537269603F61DB0D03D2104ABF789970B",
      "127.0.0.1",
      "A7BCFA5D490B351BE0754130DF03A068F855DB4333D43921125B9CF2670EF6A40370C646B90401955E1F7BC9CDBF59CE0B2C5467D820BE189C845D0B79CFC96F",
      "null",
      "E0A205EC4E7B78BBB56AFF83A733A1BB9FD39D562E67978CC5E7D73B0951DB1954595A20672A63332535E13CC6EC1E1FC8857BB09E0855D7E76E411B6FA16E9D",
      "dls.yt.uz",
      "EDC1D4AB5B02066FB3FEB9382DE6A7F8CBD095E46474B07568BC44C8DAE27B3893E75B79280EA82A38AD42D10EA0D600E6CE7E89D1629221E4363E2D78650516",
      "e-protocol.navoiyuran.uz",
      "5B29C629116C0ACB6C3D0A86141B21DE524372204239B2C6333D39B725257FD7FE7BDEC2DD594EBC19E07D7722C250E87F32F7C1AB03BE3C8C9C8B7DBB181A57",
    ]; //deployment uchun domen va token qo'shiladi

    const check = (major, minor) => {
      var newVersion = this.EIMZO_MAJOR * 100 + this.EIMZO_MINOR;
      var installedVersion = parseInt(major) * 100 + parseInt(minor);
      console.log(installedVersion, "installedVersion");
      console.log(newVersion, "newVersion");
      if (installedVersion < newVersion) {
        console.log(this.errorUpdateApp);
        alert(this.errorUpdateApp);
      } else {
        window.EIMZOClient.installApiKeys(
          () => {},
          (e, r) => {
            if (r) {
              console.log(r);
              alert(r);
            } else {
              this.wsError(e);
            }
          }
        );
      }
    };

    window.EIMZOClient.checkVersion(
      (major, minor) => check(major, minor),
      (e, r) => {
        if (r) {
          console.log(r);
          alert(r);
        } else {
          this.uiNotLoaded(e);
        }
      }
    );
  };
  uiNotLoaded = (e) => {
    if (e) {
      console.log(e);
      alert(e);
    } else {
      alert(this.errorBrowserWS);
    }
  };
  wsError = (e) => {
    if (e) {
      console.log(this.errorCAPIWS + " : " + e);
      alert(this.errorCAPIWS + " : " + e);
    } else {
      console.log(this.errorBrowserWS);
      alert(this.errorBrowserWS);
    }
  };
  uiComboSelect = (itm) => {
    if (itm) {
      var id = document.getElementById(itm);
      id.setAttribute("selected", "true");
    }
  };
  uiClearCombo = () => {
    var combo = document.getElementById("testform").key;
    combo.length = 0;
  };
  uiFillCombo = (items) => {
    var combo = document.getElementById("testform").key;
    for (var itm in items) {
      combo.append(items[itm]);
    }
  };
  uiLoaded = () => {
    //load message
  };
  uiCreateItem = (itmkey, vo) => {
    var now = new Date();
    vo.expired = window.dates.compare(now, vo.validTo) > 0;
    var itm = document.createElement("option");
    itm.value = itmkey;
    itm.text = vo.CN;
    if (!vo.expired) {
    } else {
      itm.style.color = "gray";
      itm.disabled = true;
      itm.text = itm.text + " (срок истек)";
    }
    itm.setAttribute("vo", JSON.stringify(vo));
    itm.setAttribute("id", itmkey);
    return itm;
  };

  render() {
    const sign = () => {
      console.log("sign");
      var itm = document.getElementById("testform").key.value;
      console.log(itm, "itm");
      if (itm) {
        var id = document.getElementById(itm);
        var vo = JSON.parse(id.getAttribute("vo"));
        localStorage.setItem("vo", id.getAttribute("vo"));
        var data = "data"; //har qanday string
        var keyId = this.state.keyId;
        console.log(data, "data");
        console.log(keyId, "keyId");
        if (keyId) {
          window.EIMZOClient.createPkcs7(
            keyId,
            data,
            null,
            (pkcs7) => {
              console.log(pkcs7, "pkcs7");
              this.setState({ pkcs7 }); ///send post method to back end with id and pkcs7
              loginWithERI(pkcs7);
            },
            (e, r) => {
              if (r) {
                if (r.indexOf("BadPaddingException") !== -1) {
                  console.log(this.errorWrongPassword);
                } else {
                  console.log(r);
                }
              } else {
                document.getElementById("keyId").innerHTML = "";
                console.log(this.errorBrowserWS);
              }
              if (e) this.wsError(e);
            }
          );
        } else {
          window.EIMZOClient.loadKey(
            vo,
            (id) => {
              this.setState({ keyId: id });
              localStorage.setItem("keyId", id);

              window.EIMZOClient.createPkcs7(
                id,
                data,
                null,
                (pkcs7) => {
                  this.setState({ pkcs7 }); ///send post method to back end with id and pkcs7
                  loginWithERI(pkcs7);
                },
                (e, r) => {
                  if (r) {
                    if (r.indexOf("BadPaddingException") !== -1) {
                      console.log(this.errorWrongPassword);
                    } else {
                      console.log(r);
                    }
                  } else {
                    console.log(this.errorBrowserWS);
                  }
                  if (e) this.wsError(e);
                }
              );
            },
            (e, r) => {
              if (r) {
                if (r.indexOf("BadPaddingException") !== -1) {
                  console.log(this.errorWrongPassword);
                } else {
                  console.log(r);
                }
              } else {
                console.log(this.errorBrowserWS);
              }
              if (e) this.wsError(e);
            }
          );
        }
      }
    };

    const checkUser = (e) => {
      let userName = e.target.value;
      if (userName.length > 16 || userName === "admin") {
        axios
          .get(BASE_URL + "/login/e-imzo/" + userName)
          .then((response) => {
            this.setState((prevState) => ({
              ...prevState,
              isRegisteredBefore: response.data.result,
            }));
          })
          .catch();
      }
      this.setState((prevState) => ({
        ...prevState,
        login: userName,
      }));
    };

    const loginWithERI = async (pkcs7) => {
      if (!this.state.isRegisteredBefore) {
        await axios
          .post(BASE_URL + "/login/e-imzo/" + this.state.login, {
            sessionId: this.state.id,
            sign: pkcs7,
          })
          .then((response) => {
            axios
              .post(BASE_URL + "/login/e-imzo/authenticate", {
                sessionId: this.state.id,
                sign: pkcs7,
                login: this.state.login,
              })
              .then((res) => {
                localStorage.setItem("token", `Bearer ${res.data?.id_token}`);
                dispatch.auth.authWithERI(res.data);
                API.getUserData()
                  .then((res) => {
                    dispatch.userData.userData(res.data);
                    dispatch.auth.auth();
                  })
                  .catch((error) => console.log("Auth dispatch  error", error));
              })
              .catch();
          })
          .catch();
      } else if (this.state.isRegisteredBefore) {
        await axios
          .post(BASE_URL + "/login/e-imzo/authenticate", {
            sessionId: this.state.id,
            sign: pkcs7,
            login: this.state.login,
          })
          .then((res) => {
            localStorage.setItem("token", `Bearer ${res.data?.id_token}`);
            dispatch.auth.login(res.data);
            API.getUserData()
              .then((res) => {
                dispatch.userData.userData(res.data);
                dispatch.auth.auth();
              })
              .catch((error) => console.log("Auth dispatch  error", error));
            console.log("dispatch the token");
          })
          .catch();
      }
    };

    return (
      <Wrapper>
        <div>
          <form className="form" name="testform" id="testform">
            <div className="d-flex">
              <input
                type="text"
                placeholder="-"
                id="userName"
                className="form-input input-style font-roboto-light w-100"
                onChange={checkUser}
              />
            </div>
            <select name="key" onChange={this.cbChanged}></select>
            <div className="wrapper-title">
              <button className="form-button" onClick={sign} type="button">
                <span className="icon icon-search" />
                {this.props.t("signIn")}
              </button>
            </div>
          </form>
        </div>
      </Wrapper>
    );
  }
}

export default withTranslation()(EimzoLogin);
