import styled from "styled-components";

export const ChatDiv = styled.div`
  height: 385px;
  position: relative;
  display: flex;
  justify-content: space-between;
  gap: 50px;
  margin-top: 25px;
  border-radius: 12px;
  background-color: #f6fcff;
`;

export const ChatContainer = styled.div`
  width: 93%;
  height: 72%;
  display: flex;
  flex-direction: column;
  position: absolute;
  text-align: left;
  padding: 20px;
  gap: 40px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 3px;
  }
`;

export const LeftMessageText = styled.p`
  margin: 0;
  padding: 8px 12px;
  font-size: 14px;
  border-radius: 16px;
  border: 1px solid #208667;
  color: #208667;
  background: #fff;
`;

export const LeftMessages = styled.div`
  max-width: 380px;
  align-self: flex-start;
  display: flex;
  gap: 15px;
`;

export const MessageDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-top: 5px;
`;

export const AvatarDiv = styled.div`
  width: 90px;
  min-width: 90px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0;
  font-size: 12px;
  color: #fff;
  border-radius: 10px;
  overflow: hidden;
  background-color: #065ea9;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const MessageTime = styled.span`
  align-self: ${(props) => (props.isSent ? "flex-end" : "flex-start")};
  font-size: 11px;
  color: 333;
`;

export const RightMessageText = styled.p`
  margin: 0;
  padding: 8px 12px;
  font-size: 14px;
  color: #fff;
  border-radius: 16px;
  background: #208667;
`;

export const RightMessages = styled.div`
  max-width: 380px;
  align-self: flex-end;
  display: flex;
  gap: 15px;
`;

export const SendMessage = styled.div`
  width: 80%;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%, -50%);

  form {
    width: 100%;
    height: fit-content;
  }

  .input__div {
    width: 100%;
    position: relative;
  }

  input {
    box-sizing: border-box;
    width: 100% ;
    padding: 10px 20px;
    border-radius: 16px;
    border: 1px solid #208667;
    background: #fff;
    outline: none;
    margin: 0 auto;
  }

  button {
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 6px;
    bottom: 6px;
    border-radius: 50%;
    background: #208667;
    cursor: pointer;
    border: none;
    outline: none;
    pointer-events: none;
    opacity: 0.5;
  }
`;

export const NoMessage = styled.p`
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 14px;
  transform: translate(-50%, -50%);
`;
