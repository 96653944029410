import styled from "styled-components";

export const MeetingVideoDiv = styled.div`
  position: relative;
  height: 350px;
  display: flex;
  align-items: center;
  margin-top: 25px;
  padding: 20px;
  padding-top: 30px;
  padding-left: 30px;
  padding-right: 15px;
  border-radius: 12px;
  background-color: #f2f5f9;

  .add-file-btn {
    position: absolute;
    top: 25px;
    right: 25px;
    z-index: 1;
    cursor: pointer;
    transition: 0.3s all ease;
  }

  .add-file-btn:hover {
    transform: scale(1.1);
  }
`;

export const InnerFiles = styled.div`
  width: 100%;
  height: 340px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  position: relative;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    background: #f2f5f9;
  }
`;

export const FileDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 96%;
  img {
    cursor: pointer;
  }
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    font-size: 14px;
    font-weight: 700;
    color: #208667;
    background-color: transparent;
    border: 0;
    cursor: pointer;
  }

  .btns-control {
    padding: 4px;
    padding-right: 25px;
  }

  .btn {
    padding: 5px !important;
    transition: 0.3s all ease;
  }

  .btn:hover {
    transform: scale(1.3);
  }
`;

export const NoMessage = styled.p`
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 14px;
  transform: translate(-50%, -50%);
`;
