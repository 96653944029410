import { Button, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import { BASE_URL, ROLES } from "../../../../constants";
import { StyledUProtocol } from "./style";
import ChairmanProtocol from "../ChairmanProtocol";
import ProtocolEdit from "./ProtocolEdit";
import { useTranslation } from "react-i18next";

function UserProtocol({
  meetingId,
  isCompleted,
  agendas,
  data,
  protocolId,
  memberRole,
}) {
  const [isChecked, setIsChecked] = useState(true);
  const [isEditProtocol, setIsEditProtocol] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    localStorage.setItem("membersData", JSON.stringify(data));
  }, [data]);

  

  return (
    <StyledUProtocol>
      {isCompleted ? (
        isEditProtocol ? (
          <ProtocolEdit
            setIsProtocol={setIsEditProtocol}
            meetingId={meetingId}
            agendas={agendas}
          />
        ) : (
          <ChairmanProtocol setIsProtocol={setIsEditProtocol} membersData={data} isEdit={false}/>
        )
      ) : (
        <>
          <Typography color="#133b88" sx={{ fontWeight: "bold" }}>
            {t("userPage.protocol.notProtocol")}
          </Typography>
        </>
      )}
    </StyledUProtocol>
  );
}

export default UserProtocol;
