export const tabMenuTypes = {
  INFO: "INFO",
  FILES: "FILES",
  VOTES: "VOTES",
  CREATE_VOTES: "CREATE_VOTES",
  CONTROL_PANEL: "CONTROL_PANEL",
  PROTOCOL: "PROTOCOL",
  AGENDA: "AGENDA",
  MEETING_VIDEO: "MEETING_VIDEO"
  // PROTOCOL_EDIT: "PROTOCOL_EDIT",
  // PROTOCOL_CREATE: "PROTOCOL",
};

export const meetingTab = (t) => {
  return {
    active: {
      SECRETARY: [
        // {
        //   id: 6,
        //   type: tabMenuTypes.FILES,
        //   label: t("meetingStarted.navbar.agenda"),
        // },
        {
          id: 1,
          type: tabMenuTypes.CONTROL_PANEL,
          label: t("userPage.tabMenu.controlPanel"),
        },
        {
          id: 2,
          type: tabMenuTypes.INFO,
          label: t("userPage.tabMenu.chatInfo"),
        },
        {
          id: 3,
          type: tabMenuTypes.FILES,
          label: t("userPage.tabMenu.filles"),
        },
        { id: 4, type: tabMenuTypes.VOTES, label: t("userPage.tabMenu.votes") },
        {
          id: 5,
          type: tabMenuTypes.PROTOCOL,
          label: t("userPage.tabMenu.protocol"),
        },
        {
          id: 7,
          type: tabMenuTypes.MEETING_VIDEO,
          label: t("userPage.tabMenu.meetingVideo"),
        },
      ],
      CHAIRMAN: [
        {
          id: 1,
          type: tabMenuTypes.CONTROL_PANEL,
          label: t("userPage.tabMenu.controlPanel"),
        },
        {
          id: 2,
          type: tabMenuTypes.INFO,
          label: t("userPage.tabMenu.chatInfo"),
        },
        {
          id: 3,
          type: tabMenuTypes.FILES,
          label: t("userPage.tabMenu.filles"),
        },
        {
          id: 4,
          type: tabMenuTypes.CREATE_VOTES,
          label: t("userPage.tabMenu.votes"),
        },
        {
          id: 5,
          type: tabMenuTypes.PROTOCOL,
          label: t("userPage.tabMenu.protocol"),
        },
      ],
      WATCHER: [
        {
          id: 2,
          type: tabMenuTypes.INFO,
          label: t("userPage.tabMenu.chatInfo"),
        },
        {
          id: 3,
          type: tabMenuTypes.FILES,
          label: t("userPage.tabMenu.filles"),
        },
      ],
      SPEAKER: [
        {
          id: 2,
          type: tabMenuTypes.INFO,
          label: t("userPage.tabMenu.chatInfo"),
        },
        {
          id: 3,
          type: tabMenuTypes.FILES,
          label: t("userPage.tabMenu.filles"),
        },
        {
          id: 4,
          type: tabMenuTypes.CREATE_VOTES,
          label: t("userPage.tabMenu.votes"),
        },
        {
          id: 5,
          type: tabMenuTypes.PROTOCOL,
          label: t("userPage.tabMenu.protocol"),
        },
      ],
      SIMPLE: [
        {
          id: 2,
          type: tabMenuTypes.INFO,
          label: t("userPage.tabMenu.chatInfo"),
        },
        {
          id: 3,
          type: tabMenuTypes.FILES,
          label: t("userPage.tabMenu.filles"),
        },
        {
          id: 4,
          type: tabMenuTypes.CREATE_VOTES,
          label: t("userPage.tabMenu.votes"),
        },
        {
          id: 5,
          type: tabMenuTypes.PROTOCOL,
          label: t("userPage.tabMenu.protocol"),
        },
      ],
    },
    archive: {
      SECRETARY: [
        { id: 1, type: tabMenuTypes.CONTROL_PANEL, label: t("controlPanel") },
        {
          id: 2,
          type: tabMenuTypes.FILES,
          label: t("userPage.tabMenu.filles"),
        },
      ],
      CHAIRMAN: [
        { id: 1, type: tabMenuTypes.CONTROL_PANEL, label: t("controlPanel") },
        {
          id: 2,
          type: tabMenuTypes.FILES,
          label: t("userPage.tabMenu.filles"),
        },
      ],
      WATCHER: [
        {
          id: 1,
          type: tabMenuTypes.INFO,
          label: t("userPage.tabMenu.chatInfo"),
        },
        {
          id: 2,
          type: tabMenuTypes.FILES,
          label: t("userPage.tabMenu.filles"),
        },
      ],
      SPEAKER: [
        { id: 1, type: tabMenuTypes.CONTROL_PANEL, label: t("controlPanel") },
      ],
      SIMPLE: [
        { id: 1, type: tabMenuTypes.CONTROL_PANEL, label: t("controlPanel") },
        {
          id: 1,
          type: tabMenuTypes.FILES,
          label: t("userPage.tabMenu.filles"),
        },
      ],
    },
  };
};
