import { Avatar, Badge, CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";
import { NotificationItemBox } from "./style";
import { AvatarImage } from "../../assets/images";
import { notificationGetTime } from "./extra";

import { ROLES } from "../../constants";
import MyButton from "../UI/MyButton/MyButton";
import { green } from "@mui/material/colors";
import { notificationRedType } from "./NotificationContent";
import { useState } from "react";
import { t } from "i18next";
import { useSelector } from "react-redux";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const NotificationItem = (props) => {
  const lan = localStorage.getItem("i18nextLng");
  const [activeNotification, setActiveNotification] = useState(null);
  const { readLoading, id } = props;
  const { lng } = useSelector ((store) => store.language);

  const { day, hours } = notificationGetTime(
    props?.meetingDTO?.startRegistration,
    lan
  );

  const handleClick = (role, type) => {
    props.handleClick({
      meetingId: props?.meetingDTO?.id,
      role,
      notificationId: props.id,
      status: props?.meetingDTO?.status,
      type,
    });

    setActiveNotification(id);
  };

  return (
    <NotificationItemBox>
      <div className="notification-item-top">
        <StyledBadge
          overlap="circular"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          variant="dot"
        >
          <Avatar
            sx={{ width: "32px", height: "32px" }}
            alt="Remy Sharp"
            src={AvatarImage}
          />
        </StyledBadge>
        <div>
          <div className="notification-item-content">
            <span className="notification-item-content-text">
              {props?.meetingDTO[`companyName_${lng}`]}
            </span>
            {props.loading && props.activeMeetingId === props.meetingDTO?.id ? (
              <MyButton>
                <CircularProgress
                  size={24}
                  sx={{
                    color: green[500],
                  }}
                />
              </MyButton>
            ) : props.activeMeetingId === props.meetingDTO?.id ? (
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  flexDirection: "column",
                }}
              >
                {props.userRole?.map((role, idx) => (
                  <>
                    <MyButton
                      color="#fff"
                      background="#208667"
                      padding="7px 12px"
                      backgroundHover="#23c594"
                      onClick={() =>
                        handleClick(role, notificationRedType.OPEN)
                      }
                    >
                      {role?.memberTypeEnum === ROLES.SIMPLE
                        ? t("userPage.meetingManagement.memberBoard")
                        : role?.memberTypeEnum === ROLES.SECRETARY
                        ? t("meetingCreated.roles.secretary")
                        : role?.memberTypeEnum === ROLES.SPEAKER
                        ? t("meetingCreated.roles.speaker")
                        : role?.memberTypeEnum === ROLES.CHAIRMAN
                        ? t("meetingCreated.roles.chairman")
                        : t("meetingCreated.roles.watcher")}
                    </MyButton>
                    {readLoading && id === activeNotification ? (
                      <span>
                        <CircularProgress
                          size={24}
                          sx={{
                            color: green[500],
                          }}
                        />
                      </span>
                    ) : (
                      <MyButton
                        color="#fff"
                        background="#208667"
                        padding="7px 12px"
                        backgroundHover="#23c594"
                        onClick={() =>
                          handleClick(role, notificationRedType.CLOSE)
                        }
                      >
                        {t("notificationClose")}
                      </MyButton>
                    )}
                  </>
                ))}
              </div>
            ) : (
              <>
                {readLoading && id === activeNotification ? (
                  <span>
                    <CircularProgress
                      size={24}
                      sx={{
                        color: green[500],
                      }}
                    />
                  </span>
                ) : (
                  <MyButton
                    color="#fff"
                    background="#208667"
                    padding="7px 12px"
                    backgroundHover="#23c594"
                    onClick={() => handleClick(null, notificationRedType.CLOSE)}
                  >
                    {t("notificationClose")}
                  </MyButton>
                )}
              </>
            )}
          </div>
          <p className="notification-item-time">
            {day} {hours} da
          </p>
        </div>
      </div>
    </NotificationItemBox>
  );
};

export default NotificationItem;
