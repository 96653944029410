import styled from "styled-components";
import { MIN_LENGTH } from "../../../../constants";

export const Wrapper = styled.div`
  background-image: var(--bgImage);
  background-repeat: repeat !important;

  .container {
    max-width: ${MIN_LENGTH}px;
    margin: auto;
    padding: 30px 20px;
    padding-bottom: 50px;
  }

  .create-top {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;

    svg {
      fill: #208667;
    }
  }

  h5 {
    color: #208667;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.2px;
  }

  a.special {
    color: rgba(32, 134, 103, 0.5);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.2px;
  }

  .meeting-active {
    border-radius: 8px;
    background: #208667;
    color: #fff;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    min-width: 200px;
    height: 45px;
    white-space: nowrap;
    border: none;
    cursor: pointer;
    margin-left: 20px;
  }

  .meeting-archive {
    border-radius: 8px;
    border: 1px solid #208667;
    background: #fff;
    color: #208667;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    min-width: 200px;
    height: 45px;
    cursor: pointer;
    margin-left: 20px;
  }

  .meeting-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  label {
    border-radius: 8px;
    border: 1px solid #208667;
    background: #fff;
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 8px;
    width: 600px;
  }

  input {
    border: none;
    outline: none;
    width: 100%;
    padding: 6px 10px;
  }
`;

export const MeetingsTitle = styled.h2`
  margin-bottom: 20px;
  color: #208667;
  font-family: Inter;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
