import MeetingDetailLeft from "../../components/MeetingDetailLeft";
import MeetingDetailRight from "../../components/MeetingDetailRight";
import { Container, MainDiv, MeetingBody, Title } from "./style";

import MeetingDetailContext from "./context/MeetingDetailContext";
import Socket from "../../../../services/socket";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useQuery } from "react-query";
import { API } from "../../../../services/api";
import { QUERIES_NAME } from "../../../../constants/apiqueries";
import { Box, CircularProgress } from "@mui/material";
import { Link } from "react-router-dom";
import { AiOutlineCaretRight } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import { USERS_ROLES } from "../../../../constants";

const MeetingDetails = () => {
  const { isLogged, user_role } = useSelector((store) => store.auth);
  const [activeVideo, setActiveVideo] = useState(false);
  const auth = useSelector((store) => store.auth);
  const { t } = useTranslation();
  const { lng } = useSelector((store) => store.language);

  let redirect = "";

  if (auth.user_role === "ROLE_ADMIN") {
    redirect = "/admin/comp/meetings#active";
  } else if (auth.user_role === "ROLE_USER") {
    redirect = "/user/comp/meetings#active";
  }

  const memberRole = localStorage.getItem("memberRole")
    ? JSON.parse(localStorage.getItem("memberRole"))
    : {};

  const meetingId = memberRole.meetingId;
  const {
    data: meeting,
    isLoading: meetingLoading,
    error: meetingError,
  } = useQuery(QUERIES_NAME.GET_MEETING + "Id", async (payload) => {
    const response = await API.getMeetingByID(meetingId)
      .then((res) => res.data)
      .catch((err) => console.log("Meeting topilmadi:", err));
    return response;
  });

  if (meetingLoading) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height={"80vh"}
      >
        <CircularProgress
          color="success"
          style={{ width: "100px", height: "100px" }}
        />
      </Box>
    );
  }

  return (
    <>
      <MeetingDetailContext>
        <MainDiv>
          <Container>
            <Box className="create-top">
              {auth.user_role === USERS_ROLES.USER ? null : (
                <>
                  <Link to="/admin" className="special" onClick={() => "admin"}>
                    {t("home")}
                  </Link>
                  <AiOutlineCaretRight />
                </>
              )}
              <Link to="/admin/secretary" className="special">
                {t("userPage.companies")}
              </Link>
              <AiOutlineCaretRight />
              <Link
                to={redirect}
                state={{ comp: meeting?.companyId }}
                className="special"
              >
                {t("routes.controlPage.meeting")}
              </Link>
              <AiOutlineCaretRight />
              <h5>{t("meetingsList.commentMeeting")}</h5>
            </Box>
            <Title>{meeting[`companyName_${lng}`]}-{meeting.protocolNumber}</Title>
            <MeetingBody>
              {/* <MeetingDetailLeft
                activeVideo={activeVideo}
                setActiveVideo={setActiveVideo}
              /> */}
              <MeetingDetailRight
                activeVideo={activeVideo}
                setActiveVideo={setActiveVideo}
              />
            </MeetingBody>
          </Container>
        </MainDiv>
      </MeetingDetailContext>
      <Socket meetingId={meetingId} memberRole={user_role} />
    </>
  );
};

export default MeetingDetails;
