import styled from "styled-components";
import bgImg from "../../../../assets/images/kotib/2.png";

export const DetailRight = styled.div`
  width: 100%;
  padding-left: 30px;

  & > * {
    font-family: "Inter" !important;
  }

  .MuiButtonBase-root {
    color: rgba(32, 134, 103, 0.5);
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
  }

  .Mui-selected {
    color: #208667 !important;
  }

  .MuiTabs-indicator {
    height: 3px;
    border-radius: 10px;
    background-color: #208667 !important;
  }

  .MuiTabs-flexContainer {
    justify-content: space-between;
  }

  .MuiBox-root {
    padding: 0 !important;
  }

  .chat__container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 50px;

    > *:first-child {
      grid-column: span 3;
    }

    > *:last-child {
      grid-column: span 2;
    }
  }
`;

export const MainDiv = styled.div`
  height: 385px;
  position: relative;
  display: flex;
  justify-content: space-between;
  gap: 50px;
  margin-top: 25px;
  padding: 30px;
  border-radius: 12px;
  background-image: url(${bgImg});
  background-position: top 0 right 0;
  background-repeat: no-repeat;
  background-size: 250px;
  background-color: #f6fcff;

  h3 {
    margin: 0;
    color: #208667;
  }

  p {
    margin: 0;
    margin-top: 10px;
    font-size: 12px;

    span {
      color: #208667;
    }
  }

  b {
    font-size: 12px;
  }

  h5 {
    margin: 0;
  }
`;

export const InnerRightDiv = styled.div`
  text-align: center;
`;

export const TopicDiv = styled.div`
  height: 385px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: left;
  margin-top: 25px;
  padding: 30px;
  border-radius: 12px;
  background-image: url(${bgImg});
  background-position: top 0 right 0;
  background-repeat: no-repeat;
  background-size: 250px;
  background-color: #f6fcff;
`;

export const InnerTopic = styled.div`
  width: 100%;
  height: 330px;
  margin-bottom: 20px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    background: #ddf8f0;
  }

  .ck.ck-editor {
    width: 600px;
  }

  .ck-editor__editable {
    min-height: 200px;
  }
`;
