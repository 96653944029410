import { useEffect, useState, useRef } from "react";
import { NotificationIcon } from "../../assets/icons";
import NotificationContent from "./NotificationContent";
import { useQuery } from "react-query";
import { API } from "../../services/api";
import { QUERIES_NAME } from "../../constants/apiqueries";
import { IconBox } from "./style";
import { Tooltip } from "@mui/material";

const Notification = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const notificationRef = useRef(null);

  const { data, refetch } = useQuery(
    QUERIES_NAME.GET_NOTIFICATION_DATA,
    async () => {
      const response = await API.grtUnreadNotification()
        .then((res) => res.data)
        .catch((err) => console.log(err));
      return response;
    }
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    notificationRef.current = setInterval(() => {
      refetch();
    }, 300000);

    return () => {
      clearInterval(notificationRef.current);
    };
  }, [refetch]);
  return (
    <>
      <Tooltip title="Account settings">
        <IconBox
          onClick={handleClick}
          size="small"
          sx={{ ml: 2, cursor: "pointer" }}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <img src={NotificationIcon} alt="Notification" />
          {data?.length > 0 && (
            <span className="notification-count">
              {data?.length > 99 ? "99+" : data?.length}
            </span>
          )}
        </IconBox>
      </Tooltip>
      <NotificationContent
        anchorEl={anchorEl}
        open={open}
        handleClose={handleClose}
        data={data}
        refetch={refetch}
      />
    </>
  );
};

export default Notification;
