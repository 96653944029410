import React, { useState } from "react";
import styled from "styled-components";
import { AddButton } from "../../../../../../../../components";
import { useMutation, useQuery } from "react-query";
import { postUserFunc } from "../../extra";
import { WATCHER } from "../../../../../../../../constants";
import { API } from "../../../../../../../../services/api";
import { Autocomplete, TextField } from "@mui/material";
import { QUERIES_NAME } from "../../../../../../../../constants/apiqueries";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const AddUser = ({ refetch, meetingId }) => {
  const [selectUser, setSelectUser] = useState("");
  const [selectValue, setSelectValue] = useState("");
  const { lng } = useSelector((store) => store.language);
  const { t } = useTranslation();

  const { data: userData, isLoading: userDataLoading } = useQuery(
    [QUERIES_NAME.GET_USER_SEARCH_DATA, selectValue],
    async () => {
      const response = await API.getUserByMeeting(meetingId).then((res) => {
        const data = !!res.data
          ? res.data.map((el) => ({
              label: el[`fullName_${lng}`] ? el[`fullName_${lng}`] : "",
              ...el,
            }))
          : [];
        return data;
      });
      return response;
    }
  );

  const { mutate, isLoading: isAddLoading } = useMutation(postUserFunc, {
    onSuccess: (data) => {
      refetch();
      setSelectUser({ label: "" });
    },
  });

  const changeUserId = (data) => {
    setSelectUser(data);
  };

  const handleSearchUser = (val) => {
    if (userData === undefined || userData?.length > 0 || val.length <= 3) {
      setSelectValue(val);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (selectUser?.id) {
      const newObj = {
        meetingId,
        memberTypeEnum: WATCHER,
        userId: selectUser?.id,
      };

      mutate(newObj);
    }
  };

  return (
    <AddUserForumBox>
      <p className="text">{t("invited")}</p>
      <form className="form" onSubmit={onSubmit}>
        <div className="form-select">
          <Autocomplete
            className="form-select"
            disablePortal
            id="combo-box-demo"
            options={!!userData ? userData : []}
            value={selectUser ? selectUser : null}
            onChange={(_, newValue) => {
              changeUserId(newValue);
              setSelectValue("");
            }}
            loading={userDataLoading}
            loadingText={t("searchUser")}
            renderInput={(params) => (
              <TextField
                {...params}
                label=""
                required={true}
                placeholder={t("companyForm.enter")}
                value={selectValue}
                onChange={(e) => handleSearchUser(e.target.value)}
                onFocus={() => setSelectValue("")}
              />
            )}
          />
        </div>

        <AddButton type="submit" loading={isAddLoading} />
      </form>
    </AddUserForumBox>
  );
};
export default AddUser;

const AddUserForumBox = styled.div`
  .text {
    margin-bottom: 5px;
    color: var(--text-drak, #000);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .form {
    display: grid;
    align-items: center;
    grid-template-columns: minmax(100px, 232px) 48px;
    gap: 20px;
  }

  .MuiAutocomplete-hasPopupIcon {
    border-radius: 6px;
    border: 1px solid #208667;
    background: var(--interactive-enabled, #fff);
    width: 100%;
    max-width: 232px !important;
    height: 48px;
  }

  .MuiOutlinedInput-notchedOutline {
    border: 0 !important;
  }

  .MuiOutlinedInput-root {
    padding: 5px !important;
  }
`;
