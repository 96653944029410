import { DescTextarea, SingleProtocol, TitleInput } from "./style";
import { Typography } from "@mui/material";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useTranslation } from "react-i18next";
import { editInputText } from "../../../../utils";
import { useSelector } from "react-redux";

const OneProtocol = ({ protocol, setProtocolData, idx }) => {
  const { t } = useTranslation();
  const { lng } = useSelector((store) => store.language);
  const handleChange = ({ agenda, key, value }) => {
    console.log("agenda, key, value ", agenda, key, value);

    setProtocolData((prev) => {
      const data = prev.map((el) => {
        if (el?.agendaSubjectDTO?.agendaId === agenda) {
          let item = JSON.parse(JSON.stringify(el));
          if (key.includes("subject")) {
            item.agendaSubjectDTO[key] = editInputText(value);
          } else if (key.includes("text")) {
            item.votingTextDTO[key] = editInputText(value);
          } else {
            item.answerQuestionDTO.answers[0][key] = value;
          }

          return item;
        }
        return el;
      });
      return data;
    });
  };

  return (
    <SingleProtocol
      className="editor"
      key={protocol.agendaSubjectDTO?.agendaId}
    >
      <Typography sx={{ marginBottom: 1, fontSize: "20px" }} component="h2">
        {idx + 1} - {t("agenda")}
      </Typography>

      <Typography sx={{ marginTop: 3, marginBottom: 1 }}>
        {t("meetingCreated.filePage.agenda")}_uz
      </Typography>
      <TitleInput
        value={protocol.agendaSubjectDTO.subject_uz || ""}
        onChange={(e) =>
          handleChange({
            agenda: protocol.agendaSubjectDTO?.agendaId,
            key: "subject_uz",
            value: e.target.value,
          })
        }
      />

      <Typography sx={{ marginTop: 3, marginBottom: 1 }}>
        {t("meetingCreated.filePage.agenda")}_ru
      </Typography>
      <TitleInput
        value={protocol.agendaSubjectDTO.subject_ru}
        onChange={(e) =>
          handleChange({
            agenda: protocol.agendaSubjectDTO?.agendaId,
            key: "subject_ru",
            value: e.target.value,
          })
        }
      />

      <Typography sx={{ marginTop: 3, marginBottom: 1 }}>
        {t("meetingCreated.filePage.agenda")}_en
      </Typography>
      <TitleInput
        value={protocol.agendaSubjectDTO.subject_en}
        onChange={(e) =>
          handleChange({
            agenda: protocol.agendaSubjectDTO?.agendaId,
            key: "subject_en",
            value: e.target.value,
          })
        }
      />

      <Typography sx={{ marginTop: 3, marginBottom: 1 }}>
        {t("userPage.protocol.agendaInfo")}_uz
      </Typography>
      <DescTextarea
        rows="5"
        value={protocol.votingTextDTO.text_uz}
        onChange={(e) =>
          handleChange({
            agenda: protocol.agendaSubjectDTO?.agendaId,
            key: "text_uz",
            value: e.target.value,
          })
        }
      />

      <Typography sx={{ marginTop: 3, marginBottom: 1 }}>
        {t("userPage.protocol.agendaInfo")}_ru
      </Typography>
      <DescTextarea
        rows="5"
        value={protocol.votingTextDTO.text_ru}
        onChange={(e) =>
          handleChange({
            agenda: protocol.agendaSubjectDTO?.agendaId,
            key: "text_ru",
            value: e.target.value,
          })
        }
      />

      <Typography sx={{ marginTop: 3, marginBottom: 1 }}>
        {t("userPage.protocol.agendaInfo")}_en
      </Typography>
      <DescTextarea
        rows="5"
        value={protocol.votingTextDTO.text_en}
        onChange={(e) =>
          handleChange({
            agenda: protocol.agendaSubjectDTO?.agendaId,
            key: "text_en",
            value: e.target.value,
          })
        }
      />

      <Typography sx={{ marginTop: 3, marginBottom: 1 }}>
        {protocol.subject_uz}
      </Typography>
      <CKEditor
        editor={ClassicEditor}
        data={protocol.answerQuestionDTO.answers[0].answer_uz}
        config={{ removePlugins: ["Autoformat"] }}
        onReady={(editor) => {
          console.log("Editor is ready to use!", editor);
        }}
        onChange={(event, editor) => {
          const data = editor.getData();
          handleChange({
            agenda: protocol.agendaSubjectDTO?.agendaId,
            key: "answer_uz",
            value: data,
          });
        }}
      />

      <Typography sx={{ marginTop: 3, marginBottom: 1 }}>
        {protocol.subject_ru}
      </Typography>
      <CKEditor
        editor={ClassicEditor}
        data={protocol.answerQuestionDTO.answers[0].answer_ru}
        config={{ removePlugins: ["Autoformat"] }}
        onReady={(editor) => {
          console.log("Editor is ready to use!", editor);
        }}
        onChange={(event, editor) => {
          const data = editor.getData();
          handleChange({
            agenda: protocol.agendaSubjectDTO?.agendaId,
            key: "answer_ru",
            value: data,
          });
        }}
      />

      <Typography sx={{ marginTop: 2, marginBottom: 1 }}>
        {protocol.subject_en}
      </Typography>
      <CKEditor
        editor={ClassicEditor}
        data={protocol.answerQuestionDTO.answers[0].answer_en}
        config={{ removePlugins: ["Autoformat"] }}
        onReady={(editor) => {
          console.log("Editor is ready to use!", editor);
        }}
        onChange={(event, editor) => {
          const data = editor.getData();
          handleChange({
            agenda: protocol.agendaSubjectDTO?.agendaId,
            key: "answer_en",
            value: data,
          });
        }}
      />
    </SingleProtocol>
  );
};

export default OneProtocol;
