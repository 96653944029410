import { Divider, Menu, MenuItem } from "@mui/material";
import { Fragment } from "react";
import NotificationItem from "./NotificationItem";
import styled from "styled-components";
import { useMutation, useQuery } from "react-query";
import { API } from "../../services/api";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ACTIVE, PENDING, USERS_ROLES } from "../../constants";
import { QUERIES_NAME } from "../../constants/apiqueries";
import { useState } from "react";
const PaperProps = {
  elevation: 0,
  sx: {
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    mt: 1.5,
    "& .MuiAvatar-root": {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    "&:before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: "background.paper",
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0,
    },
  },
};

export const notificationRedType = {
  OPEN: "OPEN",
  CLOSE: "CLOSE",
};
const NotificationContent = ({
  anchorEl,
  open,
  handleClose,
  data,
  refetch,
}) => {
  const navigate = useNavigate();
  const { isLogged, user_role } = useSelector((store) => store.auth);
  const [activeMeetingId, setActiveMeetingId] = useState(null);
  const user = useSelector((state) => state.userData);
  const { data: userRole, isLoading: memberTypeLoading } = useQuery(
    QUERIES_NAME.GET_NOTIFICATION_MEMBER_ROLE_BY_MEETING + activeMeetingId,
    async () => {
      if (!!activeMeetingId) {
        const response = await API.getMemberRoleByMeeting({
          userId: user.id,
          meetingId: activeMeetingId,
        })
          .then((res) => res.data)
          .catch((err) => console.log("Notification meeting", err));

        return response;
      }
    }
  );

  const { mutate, isLoading: readNotificationLoading } = useMutation(
    async ({ notificationId, meetingId, role, status, type }) => {
      return await API.notificationRedPost({
        notificationId,
      })
        .then((res) => {
          if (notificationRedType.OPEN === type) {
            localStorage.setItem(
              "memberRole",
              JSON.stringify({
                meetingId: meetingId,
                ...role,
              })
            );

            navigate(
              `/${
                isLogged && user_role === USERS_ROLES.ADMIN ? "admin" : "user"
              }/meeting-details/${meetingId}`,
              {
                state: {
                  status:
                    status === PENDING || status === ACTIVE
                      ? "active"
                      : "archive",
                },
              }
            );
          } else {
            console.log(notificationRedType.CLOSE);
          }
          handleClose();
          refetch();
        })
        .catch((err) => console.log(err));
    }
  );

  const handleClick = ({ meetingId, role, notificationId, status, type }) => {
    const newData = {
      notificationId,
      meetingId,
      role,
      status,
      type,
    };
    mutate(newData);
  };

  return (
    <NotificationBox
      anchorEl={anchorEl}
      id="account-menu"
      open={open}
      onClose={handleClose}
      // onClick={handleClose}
      PaperProps={PaperProps}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <p className="notification-title">Notification</p>
      <div className="menu-box">
        {!!data &&
          data.map((el) => (
            <Fragment key={el.id}>
              <MenuItem onClick={() => setActiveMeetingId(el?.meetingDTO?.id)}>
                <NotificationItem
                  {...el}
                  refetch={refetch}
                  handleClick={handleClick}
                  userRole={userRole}
                  activeMeetingId={activeMeetingId}
                  loading={memberTypeLoading}
                  readLoading={readNotificationLoading}
                />
              </MenuItem>
              <Divider />
            </Fragment>
          ))}
        {!(data?.length > 0) && (
          <p className="notification-err">
            Hozirda aktiv uchrashuvlar mavjud emas
          </p>
        )}
      </div>
    </NotificationBox>
  );
};

export default NotificationContent;

const NotificationBox = styled(Menu)`
  * {
    box-sizing: border-box;
  }

  font-family: var(--fontFamily);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;

  .notification-title,
  .notification-err {
    color: #1a1f36;
    padding: 0 16px;
  }

  .menu-box {
    width: 100%;
    max-width: 550px;
    max-height: 290px;
    overflow-y: auto;
  }
`;
