import {EditBody, EditContainer} from "./style";
import MyButton from "../../../../components/UI/MyButton/MyButton";
import {Button} from "@mui/material";
import React, {useEffect, useRef,useMemo , useState} from "react";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {t} from "i18next";
import {API} from "../../../../services/api";
import JoditEditor from 'jodit-react';
const ProtocolEdit = ({agendas, meetingId, setIsProtocol, lang,isProtocol}) => {
    const editor = useRef(null);
    const handleClick = () => {

        const save = async () => {
            if (isProtocol) {
                await API.saveProtocolHtml({meetingId, language: lang, html: data})
                    .then((res) => setIsProtocol(false))
                    .catch((err) => console.log(err));
            }else {
                await API.saveProtocolQRHtml({meetingId, language: lang, html: data})
                    .then((res) => setIsProtocol(false))
                    .catch((err) => console.log(err));
            }
        }
        save();
    };
    useEffect(() => {
        const fetchData = async () => {
            if (isProtocol) {
                await API.getProtocolHtml({meetingId, lang})
                    .then((res) => setData(res.data))
                    .catch((err) => console.log(err));
            }else {
                await API.getProtocolQRHtml({meetingId, lang})
                    .then((res) => setData(res.data))
                    .catch((err) => console.log(err));
            }
        }
        fetchData();
    }, [meetingId, lang]);
    const [data, setData] = useState("");
    const config =
        {
            readonly: false, // all options from https://xdsoft.net/jodit/docs/,
            placeholder:  'Start typings...'
        };
    return (
        <EditContainer>
            <EditBody>
                {/*<CKEditor*/}
                {/*    data={data}*/}
                {/*    name="editor_uz"*/}
                {/*    editor={ClassicEditor}*/}
                {/*    config={{removePlugins: ["Autoformat"]}}*/}
                {/*    onReady={(editor) => {*/}
                {/*        console.log("Editor is ready to use!", editor);*/}
                {/*    }}*/}
                {/*    onChange={(event, editor) => {*/}
                {/*        const data = editor.getData(); // Get data from CKEditor*/}
                {/*        setData(data);*/}
                {/*    }}*/}
                {/*/>*/}
                <JoditEditor
                    ref={editor}
                    value={data}
                    config={config}
                    tabIndex={1} // tabIndex of textarea
                    // onBlur={newContent => setData(newContent)} // preferred to use only this option to update the content for performance reasons
                    onChange={newContent => {console.log(newContent);
                    setData(newContent);
                    }}
                />
            </EditBody>


            <div style={{display: "flex", gap: "10px"}}>
                <Button
                    sx={{
                        mx: 1,
                        borderRadius: 2,
                    }}
                    variant="outlined"
                    onClick={() => setIsProtocol(false)}
                >
                    {t("cancel")}
                </Button>
                <MyButton
                    width="210px"
                    background="#208667"
                    color="#fff"
                    onClick={handleClick}
                >
                    {t("userPage.protocol.protocolEditBtn")}
                </MyButton>
            </div>
        </EditContainer>
    );
};

export default ProtocolEdit;
