import { init } from "@rematch/core";
import createPersistPlugin from "@rematch/persist";
import storage from "redux-persist/lib/storage";
import auth from "./models/auth";
import { getModerUserData } from "./models/getModerUserData";
import socketMeeting from "./models/socketMeeting";
import { userData } from "./models/userData";
import language from "./models/language";

const persistPlugin = createPersistPlugin({
  key: "root",
  storage,
  version: 2,
  whitelist: ["auth", "userData", "getModerUserData"],
});

const middlewares = [];
const enhancers = [];

const store = init({
  models: {
    auth: auth,
    userData: userData,
    getModerUserData: getModerUserData,
    socketMeeting: socketMeeting,
    language,
  },
  redux: {
    middlewares,
    enhancers,
    rootReducers: { RESET_APP: () => undefined },
  },
  plugins: [persistPlugin],
});

export const { dispatch } = store;

export default store;
