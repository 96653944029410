import React, { useEffect, useState } from "react";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import { API } from "../../../../../../services/api";
import { styled } from "styled-components";

const PDFViewer = ({ meetingId }) => {
  const [pdfHtmtl, setPdfHtml] = useState();
  const [lngType, setLngType] = useState("uz-en");

  useEffect(() => {
    const fetchPdfHtml = async () => {
      try {
        const response = await API.getProtocolHtml({
          meetingId: meetingId,
          lang: lngType,
        });
        setPdfHtml(response.data);
      } catch (error) {
        console.error("Error fetching PDF HTML:", error);
      }
    };
    fetchPdfHtml();
  }, [lngType, meetingId]);

  if (pdfHtmtl) {
    document.getElementById("pdf").innerHTML = pdfHtmtl;
  }

  return (
    <ProtocolPdf>
      <div className="lang-cards">
        <button
          className={lngType === "uz-en" ? "active" : ""}
          onClick={() => setLngType("uz-en")}
        >
          UZ-EN
        </button>
        <button
          className={lngType === "en-ru" ? "active" : ""}
          onClick={() => setLngType("en-ru")}
        >
          RU-EN
        </button>
        <button
          className={lngType === "uz-ru" ? "active" : ""}
          onClick={() => setLngType("uz-ru")}
        >
          UZ-RU
        </button>
      </div>
      <div id="pdf" className="pdf-card"></div>
    </ProtocolPdf>
  );
};

export default PDFViewer;

const ProtocolPdf = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  gap: 20px;

  .lang-cards {
    display: flex;
    gap: 20px;

    button {
      padding: 8px 20px;
      border: 2px solid #ddf8f0;
      font-weight: bold;
      border-radius: 7px;
      background: transparent;
      cursor: pointer;
    }

    .active {
      border-color: #208667;
    }
  }

  .active {
    border-color: #208667;
  }
`;
