import { toast } from "react-toastify";
import { API } from "../../../../../../../services/api";
import { editInputText } from "../../../../../../../utils";

export const getCompanyFunc = async () => {
  const companyId = JSON.parse(localStorage.getItem("newMeetnigId"))?.companyId;
  if (!companyId) {
    toast.error("Meeting id topilmadi");
    return;
  }
  const res = await API.getCompanyByID(companyId)
    .then((res) => res.data)
    .catch((err) => console.log("Company:", err));

  return res;
};

const answer = (agendaId, editorData_uz, editorData_ru, editorData_en) => {
  let result = [];
  result.push({
    agendaId: agendaId,
    answer_uz: editInputText(
      editorData_uz
        .replaceAll("<p>", '<p style="text-indent: 70px;padding: 0">')
        .replaceAll("&nbsp;", "")
    ),
    answer_ru: editInputText(
      editorData_ru
        .replaceAll("<p>", '<p style="text-indent: 70px;padding: 0">')
        .replaceAll("&nbsp;", "")
    ),
    answer_en: editInputText(
      editorData_en
        .replaceAll("<p>", '<p style="text-indent: 70px;padding: 0">')
        .replaceAll("&nbsp;", "")
    ),
  });
  return result;
};

export const createMeetingAgendaFunc = async ({
  t,
  data,
  issueFile,
  mutate,
  refetch,
  setData,
  meetingId,
}) => {
  return await API.createMeetingAgenda(data)
    .then((res) => {
      let answers = answer(
        res.data.id,
        data.editor_uz,
        data.editor_ru,
        data.editor_en
      );
      API.createProtocol({ answers: answers, meetingId })
        .then((res) => {
          console.log("protocol created => ", res);
        })
        .catch((err) => {
          console.log("protocol created error => ", err);
        });

      const filesArray = Array.from(issueFile.files);
      filesArray.forEach((file) => {
        const fileData = new FormData();

        fileData.append("files", file);
        fileData.append("agendaId", res.data.id);
        fileData.append("language", "uz");

        mutate({ fileData, agendaId: res.data.id });
      });

      refetch();
      setData({
        subject_uz: "",
        subject_ru: "",
        subject_en: "",
        speakerId: "",
        debateEnum: "",
        speakTimeEnum: "",
        issueInfo_uz: "",
        issueInfo_ru: "",
        issueInfo_en: "",
        editor_uz: "",
        editor_ru: "",
        editor_en: "",
      });
      toast.success(t("agendaCreated"));
    })
    .catch((err) => {
      if (err.response.data.title === "Bad Request") {
        toast.error(t("enterData"));
      } else if (err.response.data.detail.includes("Speaker not found by ID")) {
        toast.error(t("speakerNotFound"));
      }
      console.log(err);
    });
};

export const createMeetingAgendaFileFunc = async ({
  fileData,
  agendaId,
  agendaDeleteMutate,
  setIssueFile,
  t,
}) => {
  return await API.createAgendaFile(fileData)
    .then((res) => {
      toast.success(t("fileUploaded"));
    })
    .catch((err) => {
      console.log(t("fileNotUploaded"), err);
      toast.error(t("fileTryAgain"));
      toast.warning(t("recreateAgenda"));
      agendaDeleteMutate(agendaId);
    })
    .finally(() => {
      setIssueFile("");
    });
};

export const getAgendaDataFunc = async () => {
  const meetingId = JSON.parse(localStorage.getItem("newMeetnigId"));
  const res = await API.getAgendaByMeeting(meetingId)
    .then((res) => res.data)
    .catch((err) => console.log("agenda data:", err));

  return res;
};
