import { styled } from "styled-components";

export const StyledUsersVotes = styled.div``;

export const MeetingLists = styled.div`
  width: 85%;
  min-height: 300px;
  margin: 50px auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-right: 20px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background: #ddf8f0;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #208667;
  }
`;
export const MeetingCard = styled.div`
  position: relative;
  padding: 10px 20px;
  padding-bottom: 5px;
  border-radius: 12px;
  border: 2px solid #ddf8f0;
  background: #ffff;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-around;

  &:hover {
    border-color: #208667;
  }

  .show-votes {
    position: relative;
    padding: 5px 10px;
    cursor: pointer;

    &:hover {
      .voted_users_list {
        visibility: visible;
        transform: scale(1);
        opacity: 1;
      }
    }

    &:last-child {
      .voted_users_list {
        left: -70%;
      }
    }
  }

  .voted_users_list {
    display: flex;
    flex-direction: column;
    gap: 5px;
    position: absolute;
    visibility: hidden;
    z-index: 999;
    top: 25px;
    left: 10px;
    transform: scale(0.5);
    opacity: 0;
    transition: transform 0.5s ease, opacity 0.5s ease;
    border: 2px solid #208667;
    border-radius: 12px;
    padding: 10px 20px;
    width: fit-content;
    max-height: 200px;
    background: #ddf8f0;
    overflow: hidden;
    overflow-y: auto;

    p {
      width: 300px;
      text-align: left;
      padding: 0;
      margin: 0;
    }
  }
`;

export const MeetingTitle = styled.h4`
  margin: 0;
`;

export const ShowVotes = styled.span`
  color: ${(props) => props.color};
  font-weight: bold;
  font-size: 16px;
`;

export const MeetingTopic = styled.p`
  width: 100%;
  margin: 0;
  text-align: center;
  padding: 3px 10px;
  font-size: 17px;
  font-weight: 500;
  border-radius: 8px;
  background: #ddf8f0;
  text-overflow: ellipsis;
`;

export const StyledUsersList = styled.div`
  width: 90%;
  min-height: 300px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-right: 20px;
  box-sizing: border-box;

  .agenda__title {
    color: #208667;
  }

  .user__card {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 2px solid #208667;
    padding: 16px 20px;
    border-radius: 12px;
  }

  .user__name {
    margin: 0;
    padding: 3px 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 8px;
    background: #ddf8f0;
    max-width: 30%;
  }

  .user__votes {
    display: flex;
    gap: 20px;
  }
`;

export const VoteBtns = styled.button`
  background: ${(props) => props.color};
  border: none;
  cursor: pointer;
  padding: 10px 24px;
  border-radius: 8px;
  font-size: 15px;
  font-weight: 600;
  color: white;
  transition: "transform 0.2s ease";
  transform: ${(props) => (props.isSelected ? "scale(1.1)" : "scale(1)")};
  border: ${(props) =>
    props.isSelected ? `2px solid ${props.textColor}` : "none"};
  box-shadow: ${(props) =>
    props.isSelected ? "0 4px 15px rgba(0, 0, 0, 0.5)" : "none"};
`;
