import { Link, useNavigate } from "react-router-dom";
import { Wrapper } from "./style";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { API } from "../../../../../services/api";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "react-query";
import { useForm } from "react-hook-form";
import routeNames from "../../../../../constants/routeNames";
import { useTranslation } from "react-i18next";
import { QUERIES_NAME } from "../../../../../constants/apiqueries";
import { editInputText } from "../../../../../utils";
import { useSelector } from "react-redux";
import { useEffect } from "react";

function CreateCompany() {
  const navigate = useNavigate();
  const [variants, setVariants] = useState([{ label: "" }]);
  const [selectValue, setSelectValue] = useState("");
  const { t } = useTranslation();
  const { lng } = useSelector((store) => store.language);
  const [userData, setUserData] = useState([]);

  const { register, handleSubmit, watch, setValue } = useForm();

  const { isLoading: userDataLoading, refetch } = useQuery(
    [QUERIES_NAME.GET_USER_SEARCH_DATA, selectValue],
    async () => {
      const response = await API.getModerUserSearch(selectValue).then((res) => {
        const data = !!res.data
          ? res.data.map((el) => ({
              label: el[`fullName_${lng}`] ? el[`fullName_${lng}`] : "",
              ...el,
            }))
          : [];
        setUserData(data);
        return data;
      });
      return response;
    }
  );

  useEffect(() => {
    refetch();
  }, [lng, refetch]);

  const { isLoading, mutate } = useMutation(async (formData) => {
    return await API.createCompanyData(formData)
      .then((_res) => {
        toast.success(t("adminToast.infoSuccess"));
        navigate(routeNames.ADMIN_COMPANY);
      })
      .catch((err) => {
        console.log("Mutation err", err);
      });
  });

  const handleIncrementInputs = () => {
    setVariants((prev) => [...prev, { label: "" }]);
  };

  const handleDecrementInputs = (itemIdx) => {
    setVariants((prev) => prev.filter((_, idx) => idx !== itemIdx));
  };

  const handleAddToVariantsArray = (product, elIdx) => {
    const isProductSelected = variants.some((user) => user?.id === product?.id);

    if (isProductSelected) {
      if (!!product) {
        toast.warning("Bu foydalanuvchi tanlangan!");
      }
    } else {
      setVariants((prev) =>
        prev.map((el, idx) => {
          if (idx === elIdx) {
            if (!product) {
              return { label: "" };
            } else {
              return {
                ...el,
                ...product,
              };
            }
          }

          return el;
        })
      );
    }
  };

  const onSubmit = (data) => {
    const newVariants = JSON.parse(JSON.stringify(variants)).map((el) => {
      delete el.label;
      return el;
    });
    const transformDescription = (text) =>
      editInputText(
        "&nbsp;".repeat(14) +
          text.replaceAll("\n", "<br>" + "&nbsp;".repeat(14))
      );
    const postData = {
      ...data,
      chairmanId: data.chairman?.id,
      secretaryId: data.secretaryId?.id,
      participants: newVariants,
      active: true,
      description_uz: transformDescription(data.description_uz),
      description_ru: transformDescription(data.description_ru),
      description_en: transformDescription(data.description_en),
      title: data.title ? editInputText(data.title) : "",
      name_uz: editInputText(data.name_uz),
      name_ru: editInputText(data.name_ru),
      name_en: editInputText(data.name_en),
      secondDescription: editInputText(data.secondDescription),
    };
    mutate(postData, {
      onError: () => {
        toast.error(t("adminToast.companyPostInfoError"), {
          position: "top-right",
        });
      },
    });
  };

  const handleSearchUser = (val) => {
    if (userData === undefined || userData?.length > 0 || val.length <= 3) {
      setSelectValue(val);
    }
  };

  const ErrorText = ({ text }) => (
    <p
      style={{
        position: "absolute",
        bottom: "-30px",
        color: "red",
      }}
    >
      {text}
    </p>
  );

  return (
    <Wrapper>
      <div className="container">
        <Box className="create-top">
          <Link to="/admin">{t("home")}</Link>
          <Link to="/admin/company">{t("companiesList.typeCompany")}</Link>
          <h5>{t("buttonCreate")}</h5>
        </Box>
        <form className="create-form" onSubmit={handleSubmit(onSubmit)}>
          <Box className="form_inner">
            <Box className="form_inner_start">
              <label>
                <span>{t("companyForm.name")}_uz *</span>

                <input
                  {...register("name_uz", { required: true })}
                  type="text"
                  required
                  placeholder={t("companyForm.enter")}
                  className="form-input"
                />
              </label>

              <label>
                <span>{t("companyForm.name")}_en *</span>

                <input
                  {...register("name_en", { required: true })}
                  type="text"
                  required
                  placeholder={t("companyForm.enter")}
                  className="form-input"
                />
              </label>

              <label>
                <span>{t("companyForm.name")}_ru *</span>

                <input
                  {...register("name_ru", { required: true })}
                  type="text"
                  required
                  placeholder={t("companyForm.enter")}
                  className="form-input"
                />
              </label>

              <label>
                <span>{t("pages.company.companyTitle") + " uz"}</span>
                <input
                  type="text"
                  placeholder={t("companyForm.enter")}
                  {...register("title_uz")}
                  className="form-input"
                ></input>
              </label>

              <label>
              <span>{t("pages.company.companyTitle") + " en"}</span>
                <input
                  type="text"
                  placeholder={t("companyForm.enter")}
                  {...register("title_en")}
                  className="form-input"
                ></input>
              </label>

              <label>
              <span>{t("pages.company.companyTitle") + " ru"}</span>
                <input
                  type="text"
                  placeholder={t("companyForm.enter")}
                  {...register("title_ru")}
                  className="form-input"
                ></input>
              </label>

              {variants?.map((x, i) => (
                <div
                  style={{ margin: "10px 0" }}
                  className="form-selects"
                  key={i}
                >
                  <div className="form-selects-in">
                    <label>
                      <span>{t("companyForm.companyParticipants")} *</span>
                      <Autocomplete
                        className="form-select"
                        disablePortal
                        id="combo-box-demo"
                        options={!!userData ? userData : []}
                        value={x ? x : null}
                        onChange={(event, newValue) => {
                          handleAddToVariantsArray(newValue, i);
                          setSelectValue("");
                        }}
                        loading={userDataLoading}
                        loadingText="Foydalanuvchi qidirilmoqda..."
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required={true}
                            label=""
                            placeholder={t("companyForm.enter")}
                            value={selectValue}
                            onChange={(e) => handleSearchUser(e.target.value)}
                            onFocus={() => setSelectValue("")}
                          />
                        )}
                      />
                    </label>
                    {(i > 0 || variants.length === 1) && (
                      <button
                        type="button"
                        onClick={handleIncrementInputs}
                        className="form-plus"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM12 7C12.5523 7 13 7.44772 13 8V11H16C16.5523 11 17 11.4477 17 12C17 12.5523 16.5523 13 16 13H13V16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16V13H8C7.44772 13 7 12.5523 7 12C7 11.4477 7.44772 11 8 11H11V8C11 7.44772 11.4477 7 12 7Z"
                            fill="#208667"
                          />
                        </svg>
                      </button>
                    )}
                    {variants?.length > 1 && (
                      <button
                        className="form-minus"
                        type="button"
                        disabled={variants?.length <= 1}
                        onClick={() => handleDecrementInputs(i)}
                      >
                        -
                      </button>
                    )}
                  </div>
                </div>
              ))}
            </Box>

            <Box className="form_inner_end">
              <label>
                <span>{t("companyForm.companySecretary")} *</span>
                <Autocomplete
                  className="form-select"
                  disablePortal
                  id="combo-box-demo"
                  options={!!userData ? userData : []}
                  value={watch("secretaryId") ? watch("secretaryId") : null}
                  onChange={(event, newValue) => {
                    setValue("secretaryId", newValue);
                    setSelectValue("");
                  }}
                  loading={userDataLoading}
                  loadingText="Foydalanuvchi qidirilmoqda..."
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label=""
                      placeholder={t("companyForm.enter")}
                      required={true}
                      value={selectValue}
                      onChange={(e) => handleSearchUser(e.target.value)}
                      onFocus={() => setSelectValue("")}
                    />
                  )}
                />
              </label>

              <label>
                <span>{t("companyForm.companyChairman")} *</span>
                <Autocomplete
                  className="form-select"
                  disablePortal
                  id="combo-box-demo"
                  options={!!userData ? userData : []}
                  value={watch("chairman") ? watch("chairman") : null}
                  onChange={(event, newValue) => {
                    setValue("chairman", newValue);
                    setSelectValue("");
                  }}
                  loading={userDataLoading}
                  loadingText="Foydalanuvchi qidirilmoqda..."
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label=""
                      required={true}
                      placeholder={t("companyForm.enter")}
                      value={selectValue}
                      onChange={(e) => handleSearchUser(e.target.value)}
                      onFocus={() => setSelectValue("")}
                    />
                  )}
                />
              </label>

              <label>
                <span>{t("companiesList.description1")}_uz</span>
                <textarea
                  // style={{ width: 460 }}
                  placeholder={t("companyForm.enter")}
                  {...register("description_uz")}
                  className="form-textarea"
                ></textarea>
              </label>

              <label>
                <span>{t("companiesList.description1")}_en</span>
                <textarea
                  // style={{ width: 460 }}
                  placeholder={t("companyForm.enter")}
                  {...register("description_en")}
                  className="form-textarea"
                ></textarea>
              </label>

              <label>
                <span>{t("companiesList.description1")}_ru</span>
                <textarea
                  // style={{ width: 460 }}
                  placeholder={t("companyForm.enter")}
                  {...register("description_ru")}
                  className="form-textarea"
                ></textarea>
              </label>

              <label style={{ marginTop: "15px" }}>
                <span>{t("meetingCreated.meetingExtraInfo")}_uz</span>
                <input
                  type="text"
                  placeholder={t("companyForm.enter")}
                  {...register("secondDescription_uz")}
                  className="form-input"
                ></input>
              </label>

              <label style={{ marginTop: "15px" }}>
                <span>{t("meetingCreated.meetingExtraInfo")}_en</span>
                <input
                  type="text"
                  placeholder={t("companyForm.enter")}
                  {...register("secondDescription_en")}
                  className="form-input"
                ></input>
              </label>

              <label style={{ marginTop: "15px" }}>
                <span>{t("meetingCreated.meetingExtraInfo")}_ru</span>
                <input
                  type="text"
                  placeholder={t("companyForm.enter")}
                  {...register("secondDescription_ru")}
                  className="form-input"
                ></input>
              </label>
            </Box>
          </Box>

          <Box className="create-submit">
            <Button
              className="form-submit"
              variant="contained"
              color="success"
              type="submit"
            >
              {isLoading ? <CircularProgress /> : "Yaratish"}
            </Button>
          </Box>
        </form>
      </div>
    </Wrapper>
  );
}

export default CreateCompany;
