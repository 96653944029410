const initialState = {
  lng: localStorage.getItem("i18nextLng")
    ? localStorage.getItem("i18nextLng")
    : "uz",
};

const language = {
  state: initialState, // initial state
  reducers: {
    // handle state changes with pure functions
    changeLanguage(state, payload) {
      // const userRole = getUserRole(payload?.id_token);
      return {
        ...state,
        lng: payload,
      };
    },
  },
  effects: (dispatch) => ({}),
};

export default language;
