import React, { useState } from "react";
import { UserTable, Wrapper } from "./style";
import { Box, CircularProgress } from "@mui/material";
import { Link } from "react-router-dom";
import routeNames from "../../../constants/routeNames";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { PAGE_SIZE } from "../../../constants";
import { API } from "../../../services/api";
import Pagination from "../../../components/Pagination";
import { DeleteIcon, EditIcon } from "../../../assets/icons";
import { getDate } from "./../../../utils/index";
import { useTranslation } from "react-i18next";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { MyButton } from "../../../components";
import { green } from "@mui/material/colors";
import SearchMeeting from "./components/SearchMeeting/SearchMeeting";
import { useSelector } from "react-redux";

function MeetingData() {
  const [currentPage, setCurrentPage] = useState(1);
  const [menuToggle, setMenuToggle] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const { t } = useTranslation();
  const { lng } = useSelector((store) => store.language);
  const {
    data: meetings,
    isLoading,
    refetch,
  } = useQuery(`all-meetings${currentPage}`, async () => {
    const response = await API.getAllMeetingsPaginate({
      page: currentPage - 1,
      size: 10,
    });
    return response;
  });

  const { mutate, isLoading: meetingDeleteLoading } = useMutation(
    async (payload) => {
      return await API.deleteMeetingById(payload)
        .then((res) => {
          toast.success(t("dataUpdated"));
          refetch();
        })
        .catch((err) => {
          if (err.response.data.status === 500) {
            if (
              err.response.data.detail.includes(
                "Unable to find uz.depos.app.domain.User"
              )
            ) {
              toast.error(
                "Bu yig'ilish azosi o'chirib yuborilganligi sababli, yig'ilishni o'chirib bo'lmaydi."
              );
            }
          } else {
            toast.error(t("errorOccurred"));
          }
        })
        .finally(() => setMenuToggle(!menuToggle));
    }
  );

  if (isLoading) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height={"80vh"}
      >
        <CircularProgress
          color="success"
          style={{ width: "100px", height: "100px" }}
        />
      </Box>
    );
  }
  const handleToggleMenu = (evt, index) => {
    if (!evt.target.matches(".delete-icon")) {
      setMenuToggle(!menuToggle);
    }
    setActiveIndex(index);
  };

  const deleteMeeting = (id) => {
    mutate(id);
  };

  return (
    <Wrapper>
      <div className="container">
        <Box className="user-top">
          <Link className="user-link" to="/admin">
            {t("home")}
          </Link>
          <h5>{t("meetings")}</h5>
        </Box>
        <Box className="user-list">
          <SearchMeeting
            mutate={mutate}
            meetingDeleteLoading={meetingDeleteLoading}
          />
          <Link
            className="user-create"
            to={routeNames.ADMIN_MEETING_CREATE}
            onClick={() => {
              localStorage.removeItem("newMeetnigId");
            }}
          >
            {t("meetingCreate")}
          </Link>
        </Box>
        <UserTable aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>{t("idProtocol")}</TableCell>
              <TableCell align="center">{t("companyName")}</TableCell>
              <TableCell align="center">
                {t("meetingsList.statusMeeting")}
              </TableCell>
              <TableCell align="center">{t("beginningTime")}</TableCell>
              <TableCell align="center">
                {t("meetingsList.typeMeeting")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {meetings.data &&
              meetings.data?.map((meeting, index) => (
                <TableRow
                  key={index}
                  onClick={(evt) => handleToggleMenu(evt, index)}
                  className="list-item"
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    sx={{ marginLeft: "15px" }}
                    component="th"
                    scope="row"
                  >
                    {index + 1}
                  </TableCell>
                  <TableCell align="right">
                    {meeting[`companyName_${lng}`]}
                  </TableCell>
                  <TableCell align="right">
                    {meeting.status === "PENDING" || "ACTIVE" ? t("active") : t("finished")}
                  </TableCell>
                  <TableCell align="right">
                    {getDate(meeting?.startDate)}
                  </TableCell>{" "}
                  <TableCell align="right">
                    {meeting?.meetingType == "ONLINE" ? t("online") : t("offline")}
                  </TableCell>
                  {/* <TableCell align="right">{meeting?.city?.nameUz}</TableCell> */}
                  <TableCell>
                    {menuToggle && index === activeIndex && (
                      <Box position="absolute" className="menu-box">
                        {meetingDeleteLoading ? (
                          <MyButton>
                            <CircularProgress
                              size={24}
                              sx={{
                                color: green[500],
                              }}
                            />
                          </MyButton>
                        ) : (
                          <button
                            className="user-delete"
                            onClick={() => deleteMeeting(meeting.id)}
                          >
                            <img
                              src={DeleteIcon}
                              alt="DeleteIcon"
                              className="delete-icon"
                            />
                          </button>
                        )}
                        <Link
                          state={{ meetingId: meeting?.id }}
                          to={`/admin/meetings/edit/${meeting?.id}`}
                        >
                          <img src={EditIcon} alt="EditIcon" />
                        </Link>
                      </Box>
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </UserTable>
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={
            !!meetings.headers["x-total-count"] &&
            meetings.headers["x-total-count"]
          }
          pageSize={PAGE_SIZE}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div>
    </Wrapper>
  );
}
export default MeetingData;
