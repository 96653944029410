import { Box, Button, CircularProgress, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { useMutation } from "react-query";
import { ROLES } from "../../../../constants";
import { API } from "../../../../services/api";
import { StyledProtocols } from "./style";
import UserProtocol from "./UserProtocol";
import SecretarProtocol from "./SecretarProtocol";
import { AgendaContext } from "../../pages/MeetingDetails/context/MeetingDetailContext";

const Protocols = ({ data }) => {
  const [isCompleted, setIsCompleted] = useState(false);
  const [isProtocolLoading, setIsProtocolLoading] = useState(true);
  const { agendas } = useContext(AgendaContext);
  const memberRole = localStorage.getItem("memberRole")
    ? JSON.parse(localStorage.getItem("memberRole"))
    : {};

  const { mutate: checkProtocolStatus } = useMutation(
    async () => {
      return await API.checkProtocol(memberRole?.meetingId);
    },
    {
      onSuccess: (data) => {
        setIsCompleted(data.data);
        setIsProtocolLoading(false);
      },
    },
    {
      onError: (error) => {
        console.log(error, "status");
        setIsProtocolLoading(false);
      },
    }
  );

  useEffect(() => {
    checkProtocolStatus();
  }, []);

  return (
    <StyledProtocols>
      <div className="wrapper">
        {isProtocolLoading ? (
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "410px",
            }}
            className="loading-box"
          >
            <CircularProgress
              color="success"
              style={{ width: "100px", height: "100px" }}
            />
          </Box>
        ) : memberRole?.memberTypeEnum !== ROLES.SECRETARY ? (
          <SecretarProtocol
            meetingId={memberRole?.meetingId}
            agendas={agendas}
            isCompleted={isCompleted}
            checkProtocolStatus={checkProtocolStatus}
            memberRole={memberRole}
            data={data}
          />
        ) : (
          <UserProtocol
            agendas={agendas}
            meetingId={memberRole?.meetingId}
            isCompleted={isCompleted}
            data={data}
          />
        )}
      </div>
    </StyledProtocols>
  );
};

export default Protocols;
