import styled from "styled-components";
import { MIN_LENGTH } from "../../../../../../constants";
import { ControlPanelBackgroundImg } from "../../../../../../assets/images";

export const StyledCompanyEditModal = styled.div`
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Wrapper = styled.div`
  width: 75%;
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 30px;
  border-radius: 12px;
  background-image: url(${ControlPanelBackgroundImg});
  background-position: top 0 right 0;
  background-repeat: no-repeat;
  background-size: 250px;
  background-color: #f6fcff;

  .MuiTypography-root {
    text-align: left !important;
  }

  .container {
    box-sizing: border-box;
    margin: auto;
    padding: 20px;
    padding-bottom: 50px;
    width: 100%;
    overflow-y: scroll;
  }

  h5 {
    color: #208667;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.2px;
  }

  a {
    color: rgba(32, 134, 103, 0.5);
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.2px;
  }

  .form_inner {
    display: grid;
    grid-template-columns: minmax(150px, 520px) minmax(150px, 480px);
    align-items: start;
    justify-content: space-between;
  }

  .form_inner_start,
  .form_inner_end {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .form-submit {
    margin-top: 25px;
  }

  .btn_submit {
    color: white;
    margin-right: 25px;
    margin-left: 10px;
  }

  .create-form {
    border-radius: 12px;
    background: #fff;
    box-shadow: 0px 2px 25px 0px rgba(0, 0, 0, 0.08);
    padding: 20px;
  }

  label {
    display: flex;
    flex-direction: column;
    color: var(--text-drak, #000);
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;
  }
  .form-input,
  .form-textarea {
    border-radius: 6px;
    border: 1px solid #208667;
    background: var(--interactive-enabled, #fff);
    padding: 5px 12px;
    outline: none;
    margin-top: 7px;
  }

  .form-input {
    height: 30px;
    max-width: 100%;
  }

  .form-textarea {
    height: 100px;
    max-width: 100%;
  }

  .create-select {
    border-radius: 6px;
    border: 1px solid #208667;
    background: var(--interactive-enabled, #fff);
    padding: 5px 12px;
    width: 100%;
    outline: none;
    height: 40px;
    margin-top: 7px;
  }

  .form-selects {
    width: 100%;
    max-width: 520px;

    .form-select {
      width: 400px;

      @media only screen and (max-width: 1046px) {
        width: 360px;
      }
    }
  }

  .form-selects-in {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 520px;
  }
  .form-plus {
    border-radius: 8px;
    border: 1px solid #208667;
    background: #fff;
    width: 42px;
    height: 42px;
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 15px;
    cursor: pointer;
  }

  .form-minus {
    border-radius: 8px;
    border: 1px solid #208667;
    background: #fff;
    width: 42px;
    height: 42px;
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 15px;
    font-size: 25px;
    font-weight: bold;
    color: #208667;
    cursor: pointer;
  }

  .form-select {
    background: var(--interactive-enabled, #fff);
    width: 100%;
    max-width: 520px;
    outline: none;
    height: 40px;
    margin-top: 7px;

    .MuiAutocomplete-input {
      color: var(--text-drak, #000);
      font-family: "Inter", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .MuiAutocomplete-inputRoot {
      padding: 3px;
    }
    .MuiOutlinedInput-notchedOutline {
      border: 1px solid #208667;
    }

    .Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: #208667 !important;
      border-width: 1px !important;
    }

    .MuiFormLabel-root {
      top: -5px;
    }

    .MuiFormLabel-root.Mui-focused {
      color: #208667;
      top: 0px;
    }
  }

  .create-submit {
    display: flex;
    justify-content: flex-end;
  }
`;

export const EditContainer = styled.div`
  width: 80%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  border-radius: 12px;
  background-image: url(${ControlPanelBackgroundImg});
  background-position: top 0 right 0;
  background-repeat: no-repeat;
  background-size: 250px;
  background-color: #f6fcff;

  .MuiTypography-root {
    text-align: left !important;
  }
`;
