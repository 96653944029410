import { UserTable, Wrapper } from "./style";
import routeNames from "../../../constants/routeNames";
import { useMutation, useQuery } from "react-query";
import { API } from "../../../services/api";
import { toast } from "react-toastify";
import Pagination from "../../../components/Pagination";
import { PAGE_SIZE } from "../../../constants";
import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";
import { DeleteIcon, EditIcon } from "../../../assets/icons";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useSelector } from "react-redux";

function CompanyData() {
  const [menuToggle, setMenuToggle] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [orderType, setOrderType] = useState("asc");
  const { data: companies, refetch } = useQuery("all-company", async () => {
    const response = await API.getCompaniesData({
      orderType: orderType,
    })
      .then((res) => res?.data)
      .catch((error) => console.log("Fetch Companies Error", error));
    return response;
  });
  const { t } = useTranslation();
  const { lng } = useSelector((store) => store.language);

  useEffect(() => {
    refetch();
  }, [orderType, refetch]);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PAGE_SIZE;
    const lastPageIndex = firstPageIndex + PAGE_SIZE;
    return !!companies && companies?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, companies]);

  const { mutate } = useMutation(async (payload) => {
    return await API.adminCompaniesDelete(payload)
      .then((res) => {
        toast.success("Malumotlar yangilandi!");
        refetch();
      })
      .catch((err) => {
        if (
          err?.response?.data?.detail ===
          "By this company already has created member!"
        ) {
          toast.error(t("companyForm.deleteCompanyErrorText1"));
        } else if (
          err?.response?.data?.detail ===
          "By this company already has created meeting!"
        ) {
          toast.error(t("companyForm.deleteCompanyErrorText2"));
        } else {
          toast.error("Xatolik yuzberdi!");
        }
      });
  });

  const handleToggleMenu = (index) => {
    setMenuToggle(!menuToggle);
    setActiveIndex(index);
  };

  const deleteCompony = (id) => {
    mutate(id);
  };
  const changeOrderType = () => {
    setOrderType(orderType === "asc" ? "desc" : "asc");
  };

  return (
    <Wrapper>
      <div className="container">
        <Box className="user-top">
          <Link className="user-link" to="/admin">
            {t("home")}
          </Link>
          <h5>{t("companiesList.typeCompany")}</h5>
        </Box>
        <Box className="user-top">
          <Link className="user-create" to={routeNames.ADMIN_COMPANY_CREATE}>
            {t("buttonCreate")}
          </Link>
        </Box>
        <UserTable aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">T/R</TableCell>
              <TableCell>
                <div className="companyName_card" onClick={changeOrderType}>
                  {t("companiesList.nameCompany").toUpperCase()}
                </div>
              </TableCell>
              {/* <TableCell  align="center">Secretary</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {currentTableData &&
              currentTableData?.map((company, index) => (
                <TableRow
                  key={index}
                  onClick={() => handleToggleMenu(index)}
                  className="list-item"
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center" component="th" scope="row">
                    {index + 1}
                  </TableCell>
                  <TableCell align="right">{company[`name_${lng}`]}</TableCell>
                  <TableCell>
                    {menuToggle && index === activeIndex && (
                      <Box position="absolute" className="menu-box">
                        <button
                          className="user-delete"
                          onClick={() => deleteCompony(company.id)}
                        >
                          <img src={DeleteIcon} alt="DeleteIcon" />
                        </button>
                        <Link
                          state={{ companyId: company?.id }}
                          to={routeNames.ADMIN_COMPANY_EDIT}
                        >
                          <img src={EditIcon} alt="EditIcon" />
                        </Link>
                      </Box>
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </UserTable>
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={!!companies && companies?.length}
          pageSize={PAGE_SIZE}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div>
    </Wrapper>
  );
}

export default CompanyData;
