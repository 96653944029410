import React, { useEffect } from "react";
import {
  ArchiveMeetingCardInner,
  ArchiveMeetingDetailRightBox,
  ArchiveMeetingDetailRightCard,
  ArchiveMeetingTitle,
  ArchiveMeetingTopic,
  ProtocolLink,
} from "./style";
import { useQuery } from "react-query";
import { QUERIES_NAME } from "../../../../../../constants/apiqueries";
import { API } from "../../../../../../services/api";
import { Box, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { BASE_URL } from "../../../../../../constants";
import { useTranslation } from "react-i18next";

const ArchiveMeetingDetailRight = ({ meetingId }) => {
  const { isLogged, user_role } = useSelector((store) => store.auth);
  const { state } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { lng } = useSelector((store) => store.language);
  const {
    data: agendas,
    isLoading,
    error,
    refetch,
  } = useQuery([QUERIES_NAME.GET_AGENDES, meetingId], async (payload) => {
    const response = await API.getAgendaByMeeting({ id: meetingId })
      .then((res) => res.data)
      .catch((err) => console.log(err));
    return response;
  });
  useEffect(() => {
    refetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetingId]);

  if (!!error) {
    return <p>Hatolik yuzaga keldi</p>;
  }

  const handleClick = (agenda) => {
    const memberRole = localStorage.getItem("memberRole")
      ? JSON.parse(localStorage.getItem("memberRole"))
      : {};
    localStorage.setItem(
      "memberRole",
      JSON.stringify({ ...memberRole, agendaId: agenda.id })
    );

    if (isLogged && user_role === "ROLE_ADMIN") {
      navigate(`/admin/meeting-details/${agenda.meetingId}`, {
        state: { ...state, status: "archive" },
      });
    } else {
      navigate(`/user/meeting-details/${agenda.meetingId}`, {
        state: { ...state, status: "archive" },
      });
    }
  };

  return (
    <ArchiveMeetingDetailRightBox>
      {isLoading ? (
        <Box
          style={{ display: "flex", justifyContent: "center" }}
          className="loading-box"
        >
          <CircularProgress
            color="success"
            style={{ width: "100px", height: "100px" }}
          />
        </Box>
      ) : (
        <>
          <div className="meeting-right-top">
            <h5 className="meeting-right-title">
              {t("meetingStarted.navbar.agenda")}
            </h5>
            <div className="btn-box">
              <ProtocolLink
                href={BASE_URL + "/meeting/generate-pdf?meetingId=" + meetingId}
                target="_blank"
              >
                {t("userPage.downloadProtocol")}
              </ProtocolLink>
            </div>
          </div>

          <ul className="meeting-right-list">
            {!!agendas.length ? (
              agendas.map((el) => (
                <ArchiveMeetingDetailRightCard
                  key={el.id}
                  onClick={() => handleClick(el)}
                >
                  <ArchiveMeetingCardInner>
                    <ArchiveMeetingTitle>
                      {t("userPage.subject")}
                    </ArchiveMeetingTitle>
                    <ArchiveMeetingTopic>
                      {el[`subject_${lng}`]}
                    </ArchiveMeetingTopic>
                  </ArchiveMeetingCardInner>
                  <ArchiveMeetingCardInner>
                    <ArchiveMeetingTitle>
                      {t("meetingCreated.roles.speaker")}
                    </ArchiveMeetingTitle>
                    <ArchiveMeetingTopic>
                      {el[`userName_${lng}`]}
                    </ArchiveMeetingTopic>
                  </ArchiveMeetingCardInner>
                </ArchiveMeetingDetailRightCard>
              ))
            ) : (
              <p>{t("userPage.notAgenda")}</p>
            )}
          </ul>
        </>
      )}
    </ArchiveMeetingDetailRightBox>
  );
};

export default ArchiveMeetingDetailRight;
