import React, { useState, useEffect } from "react";
import { StyledUsersList, VoteBtns } from "./style";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { BALLOT_OPTIONS, STATUSES } from "../../../../../../constants";
import { toast } from "react-toastify";
import { Button } from "@mui/material";
import { API } from "../../../../../../services/api";

const createVotesBtnData = (t) => {
  return [
    {
      id: 1,
      label: t("userPage.votes.votePositive"),
      type: BALLOT_OPTIONS.FOR,
      color:
        "linear-gradient(270deg, rgba(221, 248, 240, 0.30) 0%, rgba(6, 94, 169, 0.30) 100%), linear-gradient(270deg, #3EB250 0%, rgba(62, 178, 80, 0.60) 100%), rgba(2, 119, 21, 0.00);",
      textColor: "#3EB250",
    },
    {
      id: 2,
      label: t("userPage.votes.voteNeutral"),
      type: BALLOT_OPTIONS.REFRAIN,
      color:
        "linear-gradient(90deg, rgba(255, 0, 0, 0.24) 0%, rgba(221, 248, 240, 0.30) 100%), linear-gradient(270deg, #FFC700 0%, rgba(255, 199, 0, 0.40) 100%)",
      textColor: "#FFC700",
    },
    {
      id: 3,
      label: t("userPage.votes.voteControversy"),
      type: BALLOT_OPTIONS.AGAINST,
      color:
        "linear-gradient(90deg, rgba(255, 199, 0, 0.21) 0%, rgba(221, 248, 240, 0.30) 100%), linear-gradient(270deg, rgba(255, 0, 0, 0.90) 0%, rgba(255, 0, 0, 0.60) 100%)",
      textColor: "rgba(255, 0, 0, 0.90)",
    },
  ];
};

const UsersList = ({
  agenda,
  setIsVoting,
  members,
  meetingId,
  meetingData,
}) => {
  const { t } = useTranslation();
  const { lng } = useSelector((store) => store.language);
  const [ballots, setBallots] = useState([]);

  const handleSelectedVote = (user, vote) => {
    setBallots((ballots) => {
      const existingVoteIndex = ballots.findIndex(
        (v) => v.memberId === user.id
      );

      if (existingVoteIndex !== -1) {
        const updatedVotes = [...ballots];
        updatedVotes[existingVoteIndex].options = vote;
        return updatedVotes;
      } else {
        return [
          ...ballots,
          {
            agendaId: agenda.id,
            meetingId: meetingId,
            memberId: user.id,
            options: vote,
            votingOptionId: agenda.votingOptions[0].id,
          },
        ];
      }
    });
  };

  const getAllBallotByAgenda = async () => {
    try {
      if (!agenda || !agenda.id) {
        console.error("Agenda or agenda ID is missing");
        return;
      }
      const res = await API.getAllBallotByAgendaId(agenda.id);
      setBallots(res.data);
    } catch (error) {
      console.error("Error fetching ballots: ", error);
    }
  };

  useEffect(() => {
    getAllBallotByAgenda();
  }, []);

  const onSubmit = async () => {
    const res = await API.createBallot(ballots)
      .then((res) => {
        toast.success("muvafaqiyatli ovoz berildi!");
        setIsVoting(false);
      })
      .catch((err) => {
        toast.success("ovoz berishda hatolik yuzaga keldi!");
      });
  };

  return (
    <StyledUsersList>
      <h2 className="agenda__title">{agenda[`subject_${lng}`]}</h2>
      {members?.map((user) => (
        <div key={user.id} className="user__card">
          <p className="user__name">{user.user.fullName}</p>
          <div className="user__votes">
            {createVotesBtnData(t).map((el) => {
              const currentVote =
                ballots.find((ballot) => ballot.memberId === user.id)
                  ?.options || null;
              const isSelected = currentVote === el.type;
              return (
                <VoteBtns
                  key={el.id}
                  color={el.color}
                  textColor={el.textColor}
                  onClick={() => handleSelectedVote(user, el.type)}
                  isSelected={isSelected}
                >
                  {el.label}
                </VoteBtns>
              );
            })}
          </div>
        </div>
      ))}

      <div>
        <Button
          sx={{
            mx: 2,
            borderRadius: 2,
          }}
          variant="outlined"
          size="large"
          onClick={() => setIsVoting(false)}
        >
          {t("cancel")}
        </Button>
        <Button
          sx={{
            color: "white !important",
            borderRadius: 2,
          }}
          size="large"
          color="success"
          variant="contained"
          onClick={onSubmit}
        >
          {t("userPage.votes.createVote")}
        </Button>
      </div>
    </StyledUsersList>
  );
};

export default UsersList;
